input.input-error,
select.input-error {
  border-bottom: 2px solid #fc8181 !important;
}

.error-message {
  margin-left: 10px;
  color: #fc8181;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.submit-btn:disabled {
  opacity: 0.35;
}
