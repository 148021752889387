@import "../../style/typography.scss";
@import "../../style/util.scss";

.new {
  .bottom {
    padding: 50px;
    margin: 30px;
    display: grid;
    grid-template-columns: 2fr 4fr;
    column-gap: 30px;

    &-left {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 300px;
      }
    }

    &-right {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      row-gap: 30px;
    }
  }
}

.single__abas-principais--clinica {
  justify-content: space-between;
}
