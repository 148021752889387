@import "./colors.scss";
@import "./typography.scss";
@import "./util.scss";

.fluxo-atendimento__modal__ {
  &box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 30px;
    background-color: white;
    border-radius: 4px;
    row-gap: 15px;

    overflow-y: auto;
    max-height: 80vh;
  }

  &titulo {
    @include font-primary-regular;
    font-size: 20px;
    grid-column: 1 / -1;
    margin-bottom: 20px;
  }

  &designacao {
    @include font-primary-semiBold;
    font-size: 14px;
    color: $color-grey-dark;
    border-bottom: 1px solid #0000001f;
    padding-bottom: 10px;
    padding-left: 10px;
  }

  &cell {
    @include font-primary-regular;
    border-bottom: 1px solid #0000001f;
    padding-bottom: 15px;
    padding-left: 10px;
  }

  &textarea {
    grid-column: 1 / -1;
    margin: 0 auto;
    border: none;
    width: 100%;
    margin-top: -20px;
  }

  &observacao-titulo {
    @include font-primary-semiBold;
    margin: 20px 0 15px 0;
  }

  &bottom_btns {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 50px;
  }

  &submit__modal {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 15px;
    background-color: $color-blue;
    color: white;
    cursor: pointer;
    @include font-primary-regular;
    border-radius: 4px;

    &:hover {
      background-color: $color-blue-hover;
    }
  }

  &fechar__modal {
    grid-column: 1 / -1;
    text-align: right;
    @include font-primary-semiBold;
    cursor: pointer;

    &:hover {
      color: $color-grey-dark;
    }
  }

  &erros {
    @include font-primary-semiBold;
    color: $color-pink;
    margin-bottom: 20px;
  }
}
