@font-face {
  font-family: EuclidRegular;
  src: url("../../src/fonts/Euclid-Circular-B-Regular.ttf");
}

@font-face {
  font-family: EuclidMedium;
  src: url("../../src/fonts/Euclid-Circular-B-Medium.ttf");
}

@font-face {
  font-family: EuclidSemiBold;
  src: url("../../src/fonts/Euclid-Circular-B-SemiBold.ttf");
}

@font-face {
  font-family: EuclidLight;
  src: url("../../src/fonts/Euclid-Circular-B-Light.ttf");
}

@font-face {
  font-family: EuclidBold;
  src: url("../../src/fonts/Euclid-Circular-B-Bold.ttf");
}
