@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.stockUpdate__ {
  &table {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__row {
      display: grid;
      align-items: center;
      grid-template-columns: 4fr 2fr 1fr 1fr 1fr;
      column-gap: 10px;
      @include font-primary-regular;
      color: $color-dark;
      padding: 0;
      font-size: 14px;

      &--header {
        color: $color-grey-dark;
        border-bottom: 1px solid $color-grey-dark;
        padding: 5px;
      }

      &--danger {
        color: $color-red;
      }
    }
  }
  &erro {
    text-align: center;
    color: $color-red;
    @include font-primary-regular;
  }
}

.stockUpdate__select-container {
  display: flex;
  align-items: center;
  gap: 35px;
}

.modalStockUpdateMuiTextField > div > input,
.modalStockUpdateMuiDatePicker > div > input {
  padding: 9.5px 14px !important;
}
