@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";

.stockControllAdministrativo {
  &__container {
    flex: 2;
    overflow-y: scroll;
  }

  &__content {
    margin-top: 25px;
  }

  &__li {
    margin-top: 10px;
    @include font-primary-regular;

    &--danger {
      color: $color-red;
    }

    &--warning {
    }
  }
}
