/* .authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}


 */

.ht-logo {
  width: 200px;
  height: auto;
  justify-self: flex-start;
  margin-bottom: 30px;
}

.form-auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-main-container {
  display: flex;
  background-color: #f3f5f8;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.auth-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 70%;
  min-width: 800px;
  min-height: 600px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 50;

  &-containers {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  &-1 {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    // & img {
    //   height: 100%;
    //   width: auto;
    // }

    background: linear-gradient(
      135deg,
      rgba(43, 88, 189, 0.9),
      rgba(43, 88, 189, 0.9)
    );
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  &-2 {
    display: flex;
    flex-direction: column;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

.circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.circle-1 {
  background-color: #e3e8f2;
  left: 3%;
  top: 3%;
  transform: scale(10);
}

.circle-2 {
  background-color: #e3e8f2;
  right: 3%;
  top: 40%;
  transform: scale(10);
  z-index: 1;
}

.circle-3 {
  background-color: #66b6e9;
  left: 15%;
  bottom: 5%;
  transform: scale(4);
}

.circle-3 {
  background-color: #e3e8f2;
  left: 50%;
  top: 25%;
  transform: scale(4);
}

// .circle-4 {
//   background-color: red;
//   left: -3%;
//   top: -3%;
//   transform: scale(10);
// }
// .circle-5 {
//   background-color: red;
//   left: -3%;
//   top: -3%;
//   transform: scale(10);
// }
