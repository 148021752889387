@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.adicionarNoStock__modal {
  width: 80%;
}

.stockCadastrar__container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__row {
    display: grid;
    align-items: center;
    gap: 15px;
    grid-template-columns: 5fr 3fr 5fr 2fr 2fr 2fr 3fr 20px;
  }

  &__adicionar-btn {
    @include font-primary-semiBold;
    text-transform: uppercase;
    color: $color-blue;
    cursor: pointer;
    &:hover {
      color: $color-blue-link;
    }

    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      margin: 20px 0 10px 0;

      &__fixarCategoria {
        display: flex;
        gap: 5px;

        & label {
          @include font-primary-semiBold;
          font-size: 15px;
          color: $color-dark;
          cursor: pointer;
        }

        & input {
          cursor: pointer;
          scale: 1.2;
        }
      }
    }
  }

  &__delete-btn {
    color: $color-red;
    cursor: pointer;
  }
}

.modal__validationErrors__container {
  @include font-primary-semiBold;
  color: $color-red;
}

.categoriasStockSelect > div > div {
  padding: 9.5px 14px !important;
}
