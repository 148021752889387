@import "../../../../../style/typography.scss";

.table {
  // box-shadow: none !important;
  .cellWrapper {
    display: flex;
    align-items: center;
  }

  .tableCell {
    @include font-primary-semiBold;
    color: $color-grey-dark;
    font-size: 13px;

    a {
      text-decoration: none;
      color: $color-grey-dark;
      font-size: 13px;
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  .tableCell--heading {
    @include font-secondary-semiBold;
    color: $color-dark;
    font-size: 13px;

    &--end {
      text-align: end;
    }
  }

  .status {
    padding: 5px 10px;
    border-radius: 17px;
    @include font-primary-medium;

    &.aprovado {
      color: #12d453;
      background-color: #e5faec;
    }

    &.pendente {
      color: #fdcb7f;
      background-color: #fff7eb;
    }

    &.concluido {
      color: #12d453;
      background-color: #e5faec;
    }

    &.transformado {
      color: #11cce6;
      background-color: #ecfbfd;
    }

    &.fechado {
      color: #e64919;
      background-color: #ffccbca2;
    }
    &.INTERROMPIDO {
      color: #eb3c5f;
      background-color: #f1a7b679;
    }
  }
}

.botoes-action {
  display: flex;
  align-items: center;
}

.row-conta-liquidada {
  background-color: #92db4d;
}

.cell-conta-liquidada {
  color: white !important;
}

.icon__list {
  color: $color-grey-dark;
  font-size: 23px !important;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    color: $color-dark;
  }
}
