@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";

#filtros__modal {
  width: 35vw;
  overflow-y: auto;
}

.filtros__modal__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modalFiltros__ {
  &checkboxes,
  &radios,
  &checkboxes2,
  &selects {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  input,
  label {
    @include font-primary-regular;
  }

  &checkboxes__container,
  &radios__container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &selects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    row-gap: 30px;
    column-gap: 15px;
  }
}

.filtros__modal__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  h1 {
    @include font-primary-semiBold;
    font-size: 20px;
  }

  span {
    @include font-primary-semiBold;
    font-size: 17px;
    color: $color-blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
