.qrCode {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__link {
    font-size: 10px;
    font-family: "Open Sans", sans-serif;
    color: #5c5c5c;
    font-weight: 600;

    &--link {
      color: #202020;
    }
  }
}
