@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

$border-impresso: 1.5px solid black;

.pdf-wrapper {
  margin: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 700px;
  padding-top: 20px;
}

.republicaDeCv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &__text {
    @include font-primary-bold;
    font-size: 12px;
  }

  &__img {
    width: 60px;
    height: auto;
  }
}

.pdf-body {
  height: 640px;
  width: 100%;
  border: $border-impresso;
}

.pdf-impresso__cabecalho {
  display: flex;
  border-bottom: $border-impresso;
  &--left {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: $border-impresso;
    text-align: center;

    span {
      font-size: 7px;

      &:last-child {
        margin-bottom: 5px;
      }
    }
    img {
      max-height: 60px;
      margin-top: 2px;
    }
  }

  &--right {
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 5px;

    &__title {
      @include font-primary-bold;
      text-align: center;
      font-size: 13px;
    }

    &__campo {
      .boldSpan {
        @include font-primary-bold;
        font-size: 10px;
      }
      .normalSpan {
        margin-left: 5px;
        @include font-primary-regular;
        font-size: 10px;
      }
    }
  }
}

.pdf-impresso__middle {
  height: 305px;
  max-height: 305px;
  border-bottom: $border-impresso;
  display: flex;

  &--left {
    flex: 9;
  }
}

.middle-content {
  display: flex;
}

.pdf-impresso__middle__data {
  height: 10%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-wrap: break-word;
  margin: 0 7px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.1px;

  &:first-of-type {
    margin-top: 10px;
  }

  &--center {
    justify-content: center;
  }
}

.pdf-impresso__rodape {
  display: flex;
  align-items: center;
  padding: 3px 0;
  font-size: 11px;

  &--bold {
    @include font-primary-bold;
    margin-left: 10px;
    margin-right: 3px;
  }
}

.pdf-impresso-rodape--2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding: 5px 0;

  &__item {
    font-size: 8px;
  }
}

.pdf-impresso__bottom {
  display: flex;

  &--left {
    flex: 9;
    display: flex;

    &--1 {
      flex: 1;
      border-right: $border-impresso;
    }

    &--2 {
      flex: 1;
    }
  }

  &--right {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__span1 {
      width: 90%;
      margin-top: 10px;
      font-size: 11px;
      text-align: center;
    }

    &__span2 {
      margin-top: 10px;
      @include font-primary-bold;
      font-size: 11px;
    }
  }
}

.smallDiv {
  border-bottom: $border-impresso;
  padding: 2px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;

  &--bold {
    @include font-primary-bold;
  }

  &--bottom {
    height: 12px;
    font-size: 10px;
  }

  &--top {
    margin-bottom: 10px;
  }
}

.div__carimbo {
  height: 55px;
  border-bottom: $border-impresso;
  font-size: 11px;
  display: flex;

  .pdf-impresso__bottom-top-span {
    margin-left: 4px;
    margin-top: 2px;
  }
}

.pdf-impresso__assinaturaEData {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 110px;
  // border-bottom: $border-impresso;
  align-items: center;
  font-size: 11px;

  hr {
    border-top: $border-impresso;
    width: 80%;
    margin-top: 40px;
  }

  .data {
    margin-bottom: 5px;
  }
}

.pdf-impresso--overline {
  border-top: 1px solid black; /* Set border thickness, style, and color */
  padding-top: 1px;
}
