@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";
@import "../../../../../style/util.scss";
@import "../../../../../style/fluxo-atendimento__modals.scss";

.fluxo-atendimento__modal__cell--tratamento,
.fluxo-atendimento__modal__designacao--tratamento {
  grid-column: span 4;
}

.fluxo-atendimento__modal__cell--datas,
.fluxo-atendimento__modal__designacao--datas {
  grid-column: span 2;
}

.planos-tratamento__modal {
  display: grid;
  grid-template-columns: repeat(9, 1fr);

  &__btn-executar {
    @include font-primary-semiBold;
    font-size: 14px;
    padding: 6px 16px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-blue-1;
    border-radius: 4px;
    max-height: 23px;
    max-width: 65px;
    cursor: pointer;
    margin: 0 auto;

    &:hover {
      // background-color: #21b7c50f;
      background-color: #0c54fb14;
    }

    &-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-bottom: 0.5px solid #0000001f;
    }
  }

  &__por-agendar {
    @include font-primary-regular;
    font-size: 14px;
    color: $color-pink;
  }

  &__designacao--acao {
    // border-bottom: none;
    text-align: center;
  }

  &__textarea-evolucao {
    grid-column: 1/-1;
  }

  &__btn-finalizar {
    grid-column: 1/-1;
    max-width: 150px;
    background-color: blue;
    color: white;
    padding: 7px 15px;
    @include font-primary-regular;
    font-size: 18px;
    justify-self: end;
    border: none;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      color: $color-grey-dark;
    }
  }

  &__datePicker {
    width: 90%;
    &--1 {
      grid-column: 1/3;
    }
    &--2 {
      grid-column: 3/5;
    }
  }
}

.evolucoes__modal {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 0.5px solid #0000001f;
    padding-bottom: 5px;
  }

  &--data {
    @include font-primary-regular;
    color: $color-grey-dark;
  }

  &--evolucao {
    @include font-primary-regular;
  }

  &__sem-dados {
    @include font-primary-regular;
    font-size: 18px;
  }
}

#planoTratamento__mostrar-terminados {
  flex-direction: row;
  justify-content: left;
  margin: 5px 0 15px 5px;
  span {
    font-size: 14px;
  }
}

.fluxo-atendimento__modal__cell--planoTratamento {
  font-size: 14px;
}

#modal__executar__tratamentos {
  width: 80%;
}
