@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";

#modal__evolucao {
  width: 50%;
}

.modalEvolucao__container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;

  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d6d3d3de;
    padding-bottom: 10px;

    &--left {
      display: flex;
      flex-direction: column;
      row-gap: 7px;
      padding-bottom: 10px;
      @include font-primary-regular;

      &__evolucao {
        font-size: 13px;
      }

      &__otherinfo {
        font-size: 12px;
        color: $color-grey-dark;
      }
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    width: 15%;
    justify-content: flex-end;

    &__icon-btn {
      svg {
        width: 0.8em !important;
        height: 0.8em !important;
      }
    }
  }
}

#modalEvolucao__btns {
  margin-top: 10px;
}

.delete-confirmation {
  display: flex;
  gap: 5px;
}
