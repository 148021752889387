.pageNotFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  h2 {
    font-size: 30px;
    display: inline-block;
    margin-bottom: 30px;
  }

  a {
    font-size: 20px;
  }
}
