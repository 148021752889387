@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.prescricao {
  &__hr {
    margin-bottom: 20px;
    border-top: 1px solid $color-grey-light;
  }
}

.receita {
  &__item {
    display: grid;
    grid-template-columns: 24% 12% 12% 12% 29% 3%;
    margin-bottom: 10px;
    gap: 20px;
    align-items: center;

    &--headerDiv {
      margin-bottom: 50px;
    }

    &--header {
      @include font-primary-semiBold;
    }
  }
}
