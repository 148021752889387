@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";

.relatorio__datasContainer {
  width: 500px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.relatorio__filtros__container {
  display: flex;
  gap: 25px;
  margin-bottom: 50px;

  &__selectContainer {
    width: 200px !important;

    & > .MuiInputBase-formControl > .MuiSelect-select {
      padding: 10px 14px !important;
    }
  }
}

.relatorio__filtros__gerarDocButton {
  padding: 10px;
  background: $color-blue-other;
  border: none;
  color: white;
  @include font-primary-medium;
  cursor: pointer;

  &:hover {
    background-color: $color-blue;
  }
}
