@import "./colors.scss";
@import "./typography.scss";
.bg-white {
  background-color: $color-white;
}

.caixa {
  padding: 20px;
  background-color: $color-white;
  -webkit-box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 10px;
}

.form-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-search-field {
  font-size: 63px !important;
  position: absolute;
  right: -5px;
  cursor: pointer;
  fill: $color-blue !important;
}

.form-input input::placeholder {
  opacity: 0;
}

.form-input input,
.form-input select,
.form-input textarea {
  width: 100%;
  padding: 10px 7px;
  border-radius: 4px;
  border: 2px solid $color-grey-other;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
  @include font-primary-regular;
  color: $color-blue-dark;
}

.form-input label {
  position: absolute;
  left: 10px;
  top: 35%;
  transform: translateY(15%);
  font-size: 14px;
  color: $color-dark;
  letter-spacing: 1px;
  transition: 0.3s;
  @include font-primary-regular;
  cursor: text;
}

.label--up {
  top: 3% !important;
  left: 15px !important;
  background-color: white !important;
}

.form-input input:focus + label,
.form-input input:not(:placeholder-shown) + label {
  top: -20px;
  font-size: 13px;
  color: $color-dark;
  background: #fff;
  padding: 7px;
  @include font-primary-regular;
}

.form-input input:focus {
  border: 2px solid $color-blue;
}

//GRID
.row2 {
  grid-row: 2;
}

.takes1-2 {
  grid-column: 1 / 2;
}

.takes1-4 {
  grid-column: 1 / 4;
}

.takes1-7 {
  grid-column: 1 / 7;
}

.takes2-5 {
  grid-column: 2 / 5;
}

.takes1-3 {
  grid-column: 1 / 3;
}

.takes3-5 {
  grid-column: 3 / 5;
}

.takes4-7 {
  grid-column: 4 / 7;
}

.takes5-7 {
  grid-column: 5 / 7;
}

.span--2 {
  grid-column: span 2;
}

//Form buttons
.botoes {
  grid-column: 1/-1;
  gap: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.blue-button,
.cancel-btn {
  padding: 7px 18px;
  font-size: 14px;
  text-transform: uppercase;
  @include font-secondary-regular;
  border-radius: 3px;
  transition: all 0.2s;

  cursor: pointer;
}

.blue-button {
  display: flex;
  align-items: center;
  color: $color-white;
  background-color: $color-blue;
  border: none;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

  &:hover {
    background-color: #266fab;
  }
}

.cancel-btn {
  color: white;
  background-color: #b6bac8;
  border: none;

  &:hover {
    background-color: #acaeb7;
  }
}

.botao--pdf {
  background-color: $color-pink;
}

textarea {
  resize: none;
}

.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}

.font-bold {
  @include font-primary-bold;
}

.absolute-positioned-edit-btn {
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 12px;
  background-color: $color-blue;
  color: white;
  padding: 5px 10px;
  @include font-secondary-regular;

  cursor: pointer;
  &:hover {
    color: $color-blue;
    background-color: $color-grey-light;
  }
}

//3 dots menu
.dots__menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5px;
  margin-left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-other;
    .dot {
      background-color: $color-grey-darker;
    }
  }

  .dot {
    width: 3.5px;
    height: 3.5px;
    background-color: $color-grey-darker;
    border-radius: 100%;
  }
}

//Popover
.popOverMenu--option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-other;
  }

  span {
    @include font-primary-regular;
    color: $color-grey-darker;
  }
  &__icon {
    font-size: 18px !important;
    color: $color-grey-darker;
    margin-left: 10px;
  }
}

.ant-popover-inner {
  padding: 12px 0 !important;
}

//Ajuste na tabela clientes
.nomeDataTable {
  a {
    margin-left: 5px;
    color: $color-grey-dark;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.normalPage__container {
  margin: 30px;
  background-color: white;
}

#medicoAssociadosSelect-label {
  @include font-primary-semiBold;
  color: $color-dark;
  font-size: 19px;
}

//Making Popover padding bigger
.ant-popover-inner-content {
  padding: 0 15px !important;
}

.dashboard__ola__text {
  margin: 20px 40px;
  @include font-primary-semiBold;
  font-size: 28px;
  color: $color-dark;
}

.darkText {
  font-weight: 900;
}

.semiDarkText {
  font-weight: 700;
}
