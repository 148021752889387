@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.executarProcedimento__container {
  display: grid;
  gap: 15px;
  grid-template-columns: 3fr 1fr 1fr;

  &__item--header {
    @include font-primary-semiBold;
    color: $color-dark;
    font-size: 14px;
  }

  &__item {
    @include font-primary-semiBold;
    color: $color-grey-dark;
    font-size: 14px;
  }

  &__textarea {
    margin-top: 25px;
    grid-column: 1/-1;
    resize: none;
    border: 2px solid $color-grey-dark;
    padding: 10px;
  }
}

.executarProcedimento__step1 {
  margin: 30px;
  display: grid;
  grid-template-columns: 4fr repeat(3, 1fr) 1.5fr 1fr;
  padding-left: 35px;
  margin-top: 30px;
  color: $color-dark;
  @include font-primary-regular;

  &__lineItem {
    border-bottom: 1px solid $color-grey-light;
    padding-bottom: 2px;
    padding-top: 25px;
    font-size: 14px;

    &--1st {
      padding-left: 10px;
    }

    .cancelarButton {
      color: crimson;
      border: 1px dotted rgb(230, 67, 99);
      border-radius: 4px;
      padding: 4px 10px;
      cursor: pointer;
      @include font-secondary-medium;
      &:hover {
        background-color: crimson;
        color: white;
      }
    }
  }

  &__tabela-heading {
    @include font-secondary-semiBold;
    color: $color-grey-darker;
    border-bottom: 1px solid $color-grey-light;
    padding-bottom: 5px;
    padding-top: 15px;
    font-size: 14px;
    background-color: $color-grey-medium;

    &--1st {
      border-top-left-radius: 10px;
      padding-left: 10px;
    }

    &--last {
      border-top-right-radius: 10px;
    }
  }

  &__procedimento {
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    gap: 4px;

    &__icon {
      font-size: 14px !important;
    }

    &-completo {
      color: #12d453;
      background-color: #e5faec;
    }
    &-novo {
      color: #11cce6;
      background-color: #ecfbfd;
    }

    &-emProgresso {
      color: #fdcb7f;
      background-color: #fff7eb;
    }
  }

  &__btn-executar,
  &__btn-completo {
    max-width: 75px;
    text-align: center;
    @include font-secondary-medium;
    font-size: 13px;
    padding: 4px 10px;
  }
}

.notificacaoEmStep2 {
  @include font-primary-semiBold;
  color: crimson;
  font-size: 14px;
  text-align: center;
  margin-bottom: 50px;
}

.listaEvolucoesContainer {
  grid-column: 1/-1;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 35px;

  .evolucoesPassadasContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;

    span {
      span {
        @include font-primary-semiBold;
      }
      font-size: 14px;
    }

    textarea {
      border-color: $color-grey-dark;
      color: $color-grey-dark;
    }
  }
}

.linhaCinzenta {
  margin-top: 20px;
  grid-column: 1/-1;
  font-size: 14px;
  text-align: center;
  background-color: $color-grey-medium;
  padding: 7px 0;
  @include font-secondary-semiBold;
}

.executarProcedimento__notificacaoContainer {
  margin: 15px 30px 0 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  letter-spacing: 1.1px;

  &__titulo {
    @include font-primary-semiBold;
    color: crimson;
    font-size: 14px;
  }

  &__subTitulo {
    @include font-primary-semiBold;
    font-size: 13px;
  }

  &__descricao {
    @include font-secondary-regular;
    font-size: 13px;
  }
}
