@import "./typography.scss";
@import "./fonts.scss";
@import "./util.scss";

#root {
  font-family: "Open Sans", sans-serif;
}
.app {
  background: $color-grey-medium;
  max-width: 1900px;
  margin: 0 auto;
}
