@import "../../../../../../style/colors.scss";
@import "../../../../../../style/typography.scss";

.modalAdicionarFicheiros {
  &__container {
    &-adicionar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      @include font-primary-regular;
      font-size: 15px;

      &--svg {
        width: 150px;
        height: auto;
      }

      &--msgEscolher {
        font-size: 18px;
      }

      &--regras-ficheiro {
        color: $color-grey-dark;
      }

      &--btn-adicionar {
        padding: 10px 15px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: $color-blue;
        border: 4px;
        cursor: pointer;
        transition: all 0.2s;
        margin-top: 20px;
        margin-bottom: 20px;

        &:active {
          box-shadow: none;
        }
      }
    }
  }

  &__errorMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    color: crimson;
    @include font-primary-semiBold;
    margin-top: 25px;
    padding: 0 30px;
    text-align: center;
  }

  &__ficheiro {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__ficheiro-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &--1,
    &--2 {
      display: flex;
      gap: 15px;
    }

    &--1 {
      &__container-imagem {
        flex: 1;
        background-color: crimson;
        border-radius: 4px;
        color: white;
        display: flex;
        flex-direction: column;

        &--svg,
        &--fileType {
          margin-left: 25px;
          margin-top: 25px;
          text-align: left;
        }

        &--svg {
          fill: white;
        }

        &--fileType {
          @include font-primary-semiBold;
          font-size: 25px;
        }
      }

      &__container-info {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 20px;

        & > .form-input > input {
          color: $color-dark;
        }
      }
    }

    &--2--1 {
      flex: 1;
      display: flex;
      justify-content: space-between;
      @include font-primary-regular;
      padding: 0 10px;
      color: $color-grey-dark;

      &__deleteIcon {
        cursor: pointer;
      }
    }

    &--2--2 {
      flex: 3;
    }
  }
}
