@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

$border-atestado: 1.5px solid black;

// @media print {
//   @page {
//     size: 148mm 210mm;
//   }
// }

.pdf-wrapper {
  margin: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 700px;
  padding-top: 20px;
}

.pdf-atestado {
  &--dark {
    font-weight: bold;
  }

  &-body {
    height: 670px;
    border: $border-atestado;
    padding: 5px 15px 5px 15px;
  }

  &__cabecalho {
    display: flex;
    align-items: center;
    margin-top: 15px;

    &--left {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 80%;
        height: auto;
      }
    }

    &--right {
      flex: 3;
      display: flex;
      flex-direction: column;

      &--dark {
        font-weight: bold;
      }
      span {
        font-size: 12px;
        @include font-primary-regular;
        letter-spacing: 1px;
      }
    }
  }

  &__middle {
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
      @include font-primary-regular;
      font-size: 12px;
      line-height: 35px;
    }

    &__title {
      @include font-primary-semiBold;
      font-size: 13px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 30px;
    }

    &__data-assinatura {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 30px;
      &--carimbo {
        align-self: end;
        margin-right: 150px;
        margin-top: -20px;
      }
    }
  }

  &__rodape {
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-top: 85px;
    padding: 5px 0;

    &--bold {
      @include font-primary-bold;
      margin-left: 10px;
      margin-right: 3px;
    }
  }
}
