@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

.pdf-listaAtendimentos-heading {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 30px;
}

.pdf-listaAtendimentos-table-container {
  @include font-primary-regular;
  margin-top: 40px;
  .listaAtendimentos-table {
    width: 100%;
    border-collapse: collapse;

    td,
    th {
      padding: 5px;
      border-bottom: 1px solid $color-grey-dark;
      text-align: left;
      font-size: 12px;
    }

    th {
      @include font-primary-semiBold;
    }

    td {
      text-transform: capitalize;
    }
  }
}

.div-teste-above {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.full-screen-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
