@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.roleSelectorContainer {
  display: flex;
  gap: 10px;
  margin-right: 15px;

  &__label,
  &__select {
    @include font-primary-semiBold;
    font-size: 13px;
  }

  &__select {
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
  }
}
