@import "../../../../style/colors.scss";

#modalProcedimentosPorAgendar {
  width: 70%;
}

.modalProcedimentosPorAgendar__link {
  text-decoration: none;
  color: $color-dark;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.modalCredencial__row__expirado {
  color: crimson;
}
