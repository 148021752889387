@import "../../../style/typography.scss";

.despesasRecorrentes__botoes {
  display: flex;
  justify-content: flex-end;

  span {
    @include font-primary-regular;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      color: $color-red;
      margin-right: 25px;
    }

    &:nth-child(2) {
      color: $color-dark;
    }

    &:last-child {
      margin-left: 20px;
      color: $color-grey-dark;
    }
  }
}

.despesasRecorrentes__desativado {
  background-color: $color-grey-light;
}
