@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";

.financeiro__adicionarEFiltros {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.financeiro__filtros {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    @include font-secondary-medium;
    font-size: 14px;
  }

  select {
    border: 0;
    border-bottom: 1px solid $color-dark;
    @include font-primary-regular;
    cursor: pointer;
  }

  &__filtrar {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;

    &:hover {
      background-color: $color-grey-light;
    }

    &--ativo {
      background-color: $color-grey-light;
    }
  }

  &__periodoContainer {
    display: flex;
    align-items: center;
    gap: 15px;

    &__dataContainer {
      max-width: 150px;
    }
  }
}

.filtro__ativo {
  color: $color-blue;
}

.financeiro__filtros__icon,
.financeiro__filtros__icon-text {
  color: $color-blue;
}

//TexBox
.textBoxSearch__container {
  display: flex;
  align-items: center;
  justify-content: center;

  .textBoxSearch__input {
    border: 1px solid $color-border-input;
    padding: 5px 5px 5px 3px;
    padding-right: 20px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    color: #273445;
  }

  .textBoxSearch__input {
    border-right: none;
    &:focus {
      outline: none;
    }
  }
}

.financeiro__filtros__search-icon {
  background-color: $color-blue;
  color: white;
  padding: 2.5px 3px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  margin-left: -3px;
}
