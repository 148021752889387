@import "../../../../../../../style/colors.scss";
@import "../../../../../../../style/typography.scss";

.valorDataCaixa {
  display: flex;
  flex-direction: column;
  &-select div {
    padding: 9.5px 5px;
  }

  &__fields {
    display: flex;
    gap: 20px;
    margin: 30px 0;

    & > div {
      flex: 1;
    }

    &:last-child {
      width: 100%;
    }
  }
}

.muiDatePicker > div > input {
  height: 0.47em !important;
}
