@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

.pdf-fatura-wrapper {
  margin: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 1000px;
}

#pdf-fatura-cabecalho {
  margin-top: 25px;
}

.pdf-fatura-cabecalho-right {
  font-size: 13px;
  color: #202020;
}

.pdf-fatura-cabecalho2 {
  display: grid;
  margin-top: 25px;
  grid-template-columns: 45% 55%;

  .pdf-fatura__infoCliente {
    grid-column: 2/3;
  }
}

.pdf-fatura-cabecalho3 {
  display: grid;
  margin-top: 15px;
  grid-template-columns: 4fr 3fr;

  &__reciboData {
    grid-column: 1/2;
  }
}

.pdf-fatura__infoCliente,
.pdf-fatura__reciboData {
  font-size: 10pt;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1.1px;
  color: #5c5c5c;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.blackText {
  @include font-primary-semiBold;
  margin-bottom: 3px;
  color: #202020;
}

.headingLineFatura {
  border-top: 1px solid black;
}

.divFaturaTabela {
  display: flex;
  margin-top: 40px;

  th {
    font-size: 8pt;
    letter-spacing: 1.1px;
    color: #202020;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    font-size: 12px;

    tr,
    td,
    th {
      padding: 5px;
      color: #202020;
    }

    .normalLine {
      border-top: 1px solid grey;
    }

    .primeiraLinha {
      border-top: 1px solid black;
    }
  }
}

.pdf-fatura-rodape {
  display: flex;
  flex-direction: column;
  margin-top: auto;

  hr {
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    &1 {
      flex: 5;
    }

    &2 {
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 3px;

      &__line {
        font-size: 13px;
        letter-spacing: 1.1px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        padding: 7px;
        color: #5c5c5c;

        &--dark {
          color: #202020;
        }
      }
    }
  }

  &__elaboradoPor {
    margin-top: 40px;
    width: 30%;
    margin-left: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    color: #202020;
    gap: 10px;

    &--semEmissor {
      gap: 40px;
    }

    &__emissor {
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }

    hr {
      border-bottom: 1px solid #202020;
    }
  }

  &__poweredBy {
    letter-spacing: 1.1px;
    margin-top: 25px;
    font-family: "Roboto", sans-serif;
    font-size: 10pt;
    color: #202020;
    font-weight: 600;
  }
}

.text-bold {
  @include font-primary-bold;
}

.text-semiBold {
  @include font-primary-semiBold;
}

.right-td {
  text-align: right;
}
