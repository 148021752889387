@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.erroContainer__categoria-caixa {
  margin-top: 20px;
  color: $color-red;
  @include font-primary-semiBold;
}

#modalStockCategoria {
  width: 40%;
}

.adicionar-categoriaStock__modal__container {
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 0;
  }
}
