.modal-executar-procedimentos__datetimeContainer {
  display: flex;
  justify-content: space-around;
  max-width: 550px;
  gap: 20px;
  margin: 15px auto 20px auto;
}

#modal-executar-procedimentos {
  width: 70%;
}
