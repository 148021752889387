@import "../../../style/typography.scss";

.modal-info-container {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 40%;
  background-color: white;
  border-radius: 4px;
  padding: 15px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modal-info-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 25px;
}

.modal-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
}

.modal-info-container__success {
  border-left: 3.5px solid #2bde3f;
}

.modal-info-container__info {
  border-left: 3.5px solid $color-blue;
}

.modal-info-container__error {
  border-left: 3.5px solid #e91e63;
}

.modal-info_content h2 {
  font-size: 17px;
  margin-bottom: 5px;
  @include font-secondary-bold;
  color: $color-dark;
}

.modal-info_content p {
  font-size: 14px;
  @include font-primary-regular;
  color: $color-grey-dark;
}

.modal-info_content {
  max-width: 400px;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 250ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 250ms;
}

/* Botoes */
.modal-info-btn {
  width: 100px;
  padding: 7px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  @include font-primary-semiBold;
}

.modal-info-btn-confirm {
  background-color: #43a047;
  border: none;
  transition: all 0.2s;
  color: $color-white;
}

.modal-info-btn-confirm:hover {
  background-color: #388e3c;
}

.modal-info-btn-cancel {
  border: none;
  background-color: rgb(226 231 234);
  color: $color-grey-dark;
}

.modal-info-btn-cancel:hover {
  background-color: rgb(239, 239, 239);
}

.modal-info_close {
  padding: 5px;
  border-radius: 50%;

  &:hover {
    background-color: $color-grey-medium;
  }
}
