@import "../../../../../../style/typography.scss";
@import "../../../../../../style/colors.scss";

#modal-ver-plano {
  width: 70%;
}

.verPlanoInterrompido {
  margin: 30px;
  display: grid;
  grid-template-columns: 4fr 2fr repeat(2, 1fr) 1.5fr;
  padding-left: 35px;
  margin-top: 30px;
  color: $color-dark;
  @include font-primary-regular;

  &__lineItem {
    border-bottom: 1px solid $color-grey-light;
    padding-bottom: 2px;
    padding-top: 25px;
    font-size: 14px;

    &--1st {
      padding-left: 10px;
    }
  }

  &__tabela-heading {
    @include font-secondary-semiBold;
    color: $color-grey-darker;
    border-bottom: 1px solid $color-grey-light;
    padding-bottom: 5px;
    padding-top: 15px;
    font-size: 14px;
    background-color: $color-grey-medium;

    &--1st {
      border-top-left-radius: 10px;
      padding-left: 10px;
    }

    &--last {
      border-top-right-radius: 10px;
    }
  }

  &__procedimento {
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    gap: 4px;

    &__icon {
      font-size: 14px !important;
    }

    &-completo {
      color: #12d453;
      background-color: #e5faec;
    }

    &-interrompido {
      color: #eb3c5f;
      background-color: rgba(241, 167, 182, 0.4745098039);
    }
  }
}

.linhaCinzenta {
  margin-top: 20px;
  grid-column: 1/-1;
  font-size: 14px;
  text-align: center;
  background-color: $color-grey-medium;
  padding: 7px 0;
  @include font-secondary-semiBold;
}
