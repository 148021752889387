@import "./../../../../style/typography.scss";
@import "./../../../../style/util.scss";

.new-anamnese {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 8;
  }

  .bottom {
    padding: 50px;
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 4fr;

    &-left {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 520px;
      }
    }

    &-right {
      &-with-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        column-gap: 30px;
        row-gap: 40px;
        ///////////
      }
    }
  }
}

.two-rows {
  grid-column: span 2;
}

.row-3 {
  grid-row: 3/4;
}
