@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";
.dashboardAdministrativoTopCategorias__container {
  flex: 1;
}

.dashboardAdministrativoTopCategorias__2divs {
  display: flex;
  align-items: center;
}

.dashboardAdministrativoTopCategorias__percentagesList {
  flex: 1;
  margin-right: 15px;
  &__element {
    display: flex;
    flex-direction: column;

    span {
      color: $color-grey-dark;
    }
  }

  &__grey {
    @include font-primary-regular;
    color: $color-grey-dark;
  }
}

.ant-progress-text {
  color: $color-grey-dark !important;
  @include font-primary-regular;
}
