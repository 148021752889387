$color-grey-dark: #88959e;
$color-grey-darker: #59646b;
$color-grey-medium: #f2f5fa;
$color-grey-light: #f0f1f6;
$color-grey-other: #e2e8f2;

$color-blue-1: #0c53fb;
$color-blue: #175c93;
$color-blue-hover: #1450dc;
$color-blue-dark: #0144ea;
$color-blue-link: #3f64b2;
$color-blue-other: #005eb6;

$color-dark: #273445;

$color-orange: #ff8201;
$color-orange-dark: #df760c;

$color-pink: #eb5757;

$color-white: #ffffff;

$color-background-blue: #f1f5fb;

$color-red: crimson;

$color-border-input: rgb(106, 106, 106);
