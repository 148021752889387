@import "../../../../../../../style/typography.scss";
@import "../../../../../../../style/colors.scss";

.ver-dados-receita__modal {
  width: 30% !important;
  max-height: 80vh;
}

.dadosDaReceita {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-grey-dark;
  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    align-items: center;
    border-bottom: 1px solid $color-grey-light;
    padding: 10px 15px 5px 15px;
  }
}

.dadosDaReceita__elemento {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  padding-bottom: 10px;

  &__titulo {
    @include font-primary-regular;
    color: $color-grey-dark;
    font-size: 13px;
  }

  &__valor {
    @include font-primary-semiBold;
    color: $color-dark;
    font-size: 14px;
  }
}
