@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";

.tooltip {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1000;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__userNameProcedure {
    display: grid;
    grid-template-columns: 1fr 6fr;
    column-gap: 5px;
    align-items: center;

    &__nameProcedureContainer {
      display: flex;
      flex-direction: column;
    }

    &__svg {
      fill: $color-grey-dark;
      width: 35px;
    }

    &__name {
      @include font-primary-bold;
      font-size: 14px;
    }

    &__procedure {
      @include font-secondary-regular;
      color: $color-grey-darker;
      font-size: 13px;
    }
  }

  &__client-link {
    color: $color-dark;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__precedureInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;

    &__title {
      @include font-primary-regular;
      color: $color-grey-dark;
      font-size: 11px;
    }

    &__procedimentoName {
      font-size: 13px;
      @include font-secondary-semiBold;
      color: $color-grey-darker;
      padding-left: 5px;
    }

    &__procedimentoStatus {
      text-transform: uppercase;
      @include font-secondary-regular;
      font-size: 10px;

      & > span {
        border-radius: 10px;
        padding: 5px 10px;
      }
    }
  }

  &__dataAtendimento {
    display: flex;
    gap: 5px;
    font-size: 10px;
    color: $color-grey-darker;
    align-items: center;
    @include font-secondary-semiBold;

    &__svg {
      width: 20px;
      fill: $color-grey-darker;
    }
  }

  &__agendadoPor {
    display: flex;
    gap: 5px;

    &__title {
      @include font-primary-regular;
      color: $color-dark;
      font-size: 12px;
    }

    &__value {
      @include font-primary-semiBold;
      color: $color-dark;
      font-size: 13px;
    }
  }

  &__action-btn {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: all 0.05s;
    font-size: 13px;

    &--delete {
      color: crimson;
      border: 2px solid crimson;

      &:hover {
        color: #fff;
        background-color: crimson;
        & svg {
          fill: #fff;
        }
      }
    }

    &__svg {
      width: 20px;

      &--delete {
        fill: crimson;
      }
    }
  }

  &__title {
    @include font-primary-semiBold;
  }

  &__description {
    @include font-secondary-regular;
    color: $color-grey-darker;
    font-size: 13px;
  }
}
