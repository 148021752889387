@import "../../../../../../style/colors.scss";
@import "../../../../../../style/typography.scss";

.modal-receber__ {
  &cabecalho {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__icon {
      color: green;
    }

    &__data,
    &__divida {
      display: flex;
      align-items: center;
      gap: 7px;
    }

    &__data-divida {
      display: flex;
      gap: 20px;
    }

    &__meio-pagamento {
      @include font-primary-semiBold;
      font-size: 15px;
      margin-bottom: 30px;
    }
  }

  &tabs {
    display: flex;
    justify-content: stretch;
    background-color: $color-blue;
    border: 1px solid white;
    border-color: none;
    border-radius: 2px;
    color: white;

    &--tab {
      flex: 1;
      border-right: 1px solid $color-white;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      @include font-primary-regular;

      &:hover {
        background-color: $color-blue-other;
      }

      &--active {
        background-color: #25b7c6;
      }
    }
  }
}

//Temporario
.checkboxOcultoContainer {
  display: flex;
  padding: 20px 0 0 0;
  align-items: center;
  justify-content: center;
  @include font-primary-semiBold;
  font-size: 14px;
  gap: 10px;
  text-transform: uppercase;

  & input {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
}
