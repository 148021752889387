@import "../../style/typography.scss";
@import "../../style/colors.scss";

.stock__container {
  padding: 30px;
  margin: 30px;
}

.stock__ {
  &header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    font-size: 13px;
    text-transform: uppercase;
    @include font-primary-semiBold;

    &__container1,
    &__container2 {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    &__container1 {
      align-self: baseline;
    }

    &__container2 {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &__container1 {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }

    &__historico-btn {
      color: $color-blue-link;
      cursor: pointer;
      &:hover {
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }

  &noProducts {
    text-transform: uppercase;
    @include font-primary-semiBold;
    color: $color-blue;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
}

.select__filter-categoria {
  @include font-primary-regular;
  color: $color-dark;
  padding: 5px;
  border: none;
  border-bottom: 1px solid $color-grey-dark;
  cursor: pointer;
}

.btn__retiradas {
  color: $color-blue;
  @include font-primary-semiBold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.btn__esgotado {
  color: $color-red;
  @include font-primary-semiBold;
  cursor: default;
}

.stock-row {
  &__danger {
    & .MuiDataGrid-cell {
      color: #c62828;

      & .btn__retiradas {
        color: #c62828;
      }
    }
  }
}

.stock-row__expired {
  background-color: #ffcccb !important;
}

.stock-row__expiring-soon {
  background-color: #fffacd !important;
}
