@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.sidebar {
  transition: all 0.3s;
  flex: 1;
  min-height: 100vh;
  background-color: #fbfbfd;

  &__iconExpand {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0d8be4;
    color: white;
    font-size: 25px;
    @include font-secondary-bold;
    border-radius: 50%;
    margin-right: -12px;
    z-index: 1000;
    cursor: pointer;
    &:hover {
      background-color: #1194f2;
    }
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.sidebar__top {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbfbfd;
  padding: 20px 15px 0 15px;
}

.sidebar__logo {
  width: 100%;
  height: auto;
}

.sidebar__ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar__title {
  font-size: 10px;
  @include font-secondary-semiBold;
  letter-spacing: 1.1px;
  color: $color-grey-dark;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
}

.sidebar__li {
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 15px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #1194f210;
    border-right: 5px solid #0d8be4;
    // padding: 15px 15px 8px 20px;

    .sidebar__span {
      color: #1194f2;
    }

    .sidebar__icon {
      color: #1194f2;
    }
  }
}

.sidebar__icon {
  font-size: 17px !important;
  color: $color-grey-dark;
}

.sidebar__span {
  font-size: 11px;
  color: $color-grey-darker;
  margin-left: 13px;
  letter-spacing: 0.9px;
  @include font-secondary-regular;
}

.sidebar__bottom {
  display: flex;
  align-items: center;
  margin: 10px;
}

.sidebar__colorOption {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;

  &:nth-child(1) {
    background-color: whitesmoke;
  }
  &:nth-child(2) {
    background-color: #333;
  }
  &:nth-child(3) {
    background-color: darkblue;
  }
}

.sidebar--shrink {
  transition: all 0.2s;
  flex: 0.3;
}
