.list {
  display: flex;
  width: 100%;

  .listContainer {
    flex: 8;
  }
}

.users__label__up {
  transform: translateY(-50%) !important;
}
