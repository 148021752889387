@import "../../../../../style/typography.scss";
@import "../../../../../style/util.scss";
@import "../../../../../style/colors.scss";

.headingLine {
  th {
    text-transform: uppercase;
    font-size: 10px;
  }
}

.pdf-mapaFinanceiro-wrapper {
  margin: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 700px;
}

.pdf-mapaFinanceiro-cabecalho {
  display: flex;
  align-items: center;
  margin-top: 10px;
  img {
    height: 100px;
  }

  &--infos {
    font-family: "Open Sans", sans-serif;
    padding-top: 10px;
    flex: 2;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
  }
}

.pdf-mapaFinanceiro__tabela {
  display: flex;
  margin-top: 25px;
  font-family: "Roboto", sans-serif;

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-top: 1px solid black;
    font-size: 11px;

    tr,
    td,
    th {
      padding: 5px;
      text-align: right;
    }

    th {
    }
    .normalLine {
      border: none;
    }

    .primeiraLinha {
      border-top: 1px solid black;
      td {
        padding-top: 10px;
      }
    }
  }
}

.pdf-mapaFinanceiro__rodape {
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  color: #5c5c5c;
}

.pdf-mapaFinanceiro__titulo {
  padding-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 17px;
}

.pdf-mapaFinanceiro__resumo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  margin-top: 25px;

  &__titulo {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
  }

  &__tabela {
    display: flex;
    gap: 25px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
  }
}
