@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";

.modalListaClientesFinancas {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 35px;

    &__row {
      display: grid;
      grid-template-columns: 4fr 1fr 1fr 1fr;
      border-bottom: 1px solid grey;
      padding: 10px;
      align-items: center;
    }

    &__header {
      @include font-primary-semiBold;
      text-transform: uppercase;
    }

    &__btnEscolher {
      @include font-primary-semiBold;
      font-size: 13px;
      text-transform: uppercase;
      padding: 5px;
      border: 1px solid $color-blue;
      color: $color-blue;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        color: white;
        background-color: $color-blue;
      }
    }
  }
}
