.linkProcedimento {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.linkProcedimento:hover {
  text-decoration: underline;
}

.executarButton,
.faltouButton {
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 15px;
  background-color: transparent;
  font-size: 15px;
}

.executarButton {
  color: #20d359;
  border: 1px dotted #30b259;
  width: 80px;
}

.executarButton:hover {
  background-color: #20d359;
  color: white;
}

.faltouButton {
  color: crimson;
  border: 1px dotted crimson;
}

.faltouButton:hover {
  background-color: crimson;
  color: white;
}

.fotoCliente {
  -o-object-fit: cover;
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.fotoModal {
  width: auto;
  height: 400px;
}
/*# sourceMappingURL=Procedimento.css.map */

.listaAtendimentos__heading {
  display: flex;
  justify-content: space-between;
}

.cliente-presente {
  background-image: -webkit-linear-gradient(
    top,
    #13fb04 0%,
    #58e343 50%,
    #aded99 100%
  );
}

.cliente-nao-presente {
  background-image: -webkit-linear-gradient(
    top,
    #f9a004 0%,
    #e0ac45 50%,
    #ead698 100%
  );
}

.led {
  border-radius: 5px;
  width: 6px;
  height: 6px;
  box-shadow: 0px 0px 1.1px black;
  margin: 1px;
  zoom: 4;
}

.led:after {
  display: block;
  content: "";
  margin-left: 1px;
  margin-right: 1px;
  width: 8px;
  height: 6px;
  -webkit-border-top-right-radius: 4px 3px;
  -webkit-border-top-left-radius: 4px 3px;
  -webkit-border-bottom-right-radius: 4px 3px;
  -webkit-border-bottom-left-radius: 4px 3px;
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}
