@import "../../../../style/colors.scss";

.dasboard__middle__Earnings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  .lucro-box {
    margin-top: 18px;
    display: flex;
    flex-direction: column;

    .lucro-data {
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-top: 9px;
      }

      .lucro {
        font-size: 32px;
        color: $color-dark;
        &--red {
          color: $color-red;
        }
      }
    }

    hr {
      color: $color-grey-medium;
    }
  }
}
