@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

.administrativoBottomContainers {
  margin: 30px;
  display: flex;
  gap: 15px;

  &__container {
    flex: 1;

    &--details {
      font-size: 13px;
      color: $color-grey-dark;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    &--avalOrc {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 50px;

      &--container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
    }
  }
}

.dashboard__secretaria__heading {
  display: flex;
  justify-content: space-between;
}

.dashboard__secretaria__addCliente__container {
  display: flex;
  align-items: flex-end;
  margin-right: 30px;
}

.procedimentosPorAgendar--li {
  display: flex;
  justify-content: space-between;
}
