@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

#dividas__modal {
  width: 60%;
}

.listaDivida {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  row-gap: 15px;

  &__tableHeader {
    @include font-primary-semiBold;
    font-size: 15px;
  }

  &__clientAnchor {
    text-decoration: none;
    color: $color-dark;

    &:hover {
      text-decoration: underline;
    }
  }

  &__tableContent {
    @include font-primary-regular;
    font-size: 15px;
    color: $color-grey-dark;
  }
}

///////////////////////////////
.secretaria__dashboard__modalAcoesBtn {
  background-color: transparent;
  padding: 5px 10px;
  border: 2px solid $color-blue;
  color: $color-blue;
  @include font-primary-semiBold;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.05s;

  &:hover {
    background-color: $color-blue;
    color: #fff;
  }
}
