@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

$border-recibo: 1.5px solid black;

// @media print {
//   @page {
//     size: 148mm 210mm;
//   }
// }

.pdf-wrapper {
  margin: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 500px;
  padding-top: 20px;
}

.pdf-recibo-cabecalho {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #202020;
  display: flex;
  justify-content: space-between;
  &-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    & img {
      width: 150px;
      height: auto;
    }
  }

  &-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
    font-size: 14px;

    &__reciboTitle {
      font-size: 22px;
      text-transform: uppercase;
      color: $color-blue;
      font-weight: 600;
      letter-spacing: 1.6px;
    }

    &__nrValor {
      display: flex;
      align-items: center;
      gap: 3px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }

    &__nr,
    &__valor {
      border: 2px solid #dae8f3;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &__nr {
      width: 90px;
    }

    &__valor {
      width: 150px;
      background-color: #dae8f3;
    }
  }
}

.pdf-recibo-body {
  height: 300px;
  width: 100%;
  // border: $border-recibo;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;

  letter-spacing: 1.3px;
  padding-top: 20px;

  &__row {
    border-radius: 4px;
    padding: 6px 5px;

    &--colored {
      background-color: #dae8f3;
    }

    &__valor {
      font-weight: 500;
    }
  }

  &__dataAssinaturaContainer {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    gap: 150px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 60px;
      align-items: center;
      flex: 1;

      &--2 {
        gap: 43px;
      }

      span {
        text-transform: uppercase;
        font-weight: 500;
      }

      hr {
        width: 250px;
      }
    }
  }
}

.pdf-recibo__rodape {
  margin-top: 70px;
  font-weight: 500;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
}
