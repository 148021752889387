.clinic-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }
  span:first-child {
    margin-bottom: 10px;
  }

  span:last-child {
    margin-top: 10px;
  }
}
