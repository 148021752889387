@import "../../../style/typography.scss";

.retirarDoStock__container {
  display: flex;
  flex-direction: column;
  gap: 25px;

  &__row {
    display: grid;
    grid-template-columns: 4fr 1fr 3fr;
    gap: 15px;
  }

  &__nomeProduto {
    @include font-primary-semiBold;
  }
}
