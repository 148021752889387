@import "../../../../../../../style/typography.scss";
@import "../../../../../../../style/colors.scss";
.adicionar-entrada__modal__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  column-gap: 10px;

  &--span1 {
    grid-column: span 1;
  }

  &--span2 {
    grid-column: span 2;
  }

  &__textarea {
    grid-column: 1/-1;
    border-radius: 4px;
    resize: none;
  }

  &__checkbox-pago__container {
    display: flex;
    gap: 8px;
    align-items: center;
    @include font-primary-regular;
    margin: 25px 0 25px 5px;
    input[type="checkbox"] {
      cursor: pointer;
    }
  }
}

.adicionar-entrada__modal__pagamento {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  align-items: center;
}
.customInputPlusSign {
  color: green;
  margin-left: 10px;
  font-size: 33px;
  cursor: pointer;
}

.anexar-comprovativo {
  display: flex;
  justify-content: center;
  align-items: center;
  @include font-primary-semiBold;
  font-size: 18px;
  color: $color-blue-link;
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.entradas--regras-ficheiro {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.entradas--comprovativo-selecionado {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  @include font-primary-semiBold;
}

.modal-mensagem-de-erro {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  color: crimson;
  @include font-primary-regular;

  & > span {
    max-width: 700px;
    text-align: center;
  }
}

.adicionar-entrada__autocompleteClienteFornecedor
  > .MuiFormControl-root
  > .MuiInputBase-root {
  padding-top: 6px;
  padding-bottom: 2px;
}

.adicionar-entrada__autocompleteClienteFornecedor
  > .MuiFormControl-root
  > input {
  color: $color-blue;
  @include font-primary-regular;
  font-size: 11px !important;
}

.adicionar-entrada__autocompleteClienteFornecedor > label {
  top: -4px;
  @include font-primary-regular;
  color: #88959e;
  font-size: 12px;
}

.adicionar-entrada__modal__tipoEntrada {
  display: flex;
  gap: 15px;
  margin-bottom: 35px;

  &__btn {
    padding: 5px 10px;
    background-color: white;
    color: $color-blue;
    cursor: pointer;
    border: none;
    @include font-secondary-regular;
    font-size: 16px;

    &--active {
      background-color: $color-blue;
      color: white;
    }
  }
}

.entrada__autocomplete > div > div > input {
  height: 0.4375em;
}
