@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.navbar {
  display: flex;
  flex-direction: column;
  background-color: $color-background-blue;

  &__title {
    display: flex;
    align-items: center;
    gap: 7px;

    &__title {
      color: $color-dark;
      font-size: 20px;
      text-transform: capitalize;
      @include font-secondary-semiBold;
    }

    &__icon {
      font-size: 5px;
    }

    .navbar__select-medicos {
      border: 1px solid $color-blue;
      border-radius: 4px;
      font-size: 16px;
      color: $color-blue;
      padding: 5px;
      margin-left: 15px;
    }
  }

  .wrapper {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $color-grey-other;

    .mensagensImportantes {
      span {
        @include font-primary-semiBold;
        text-transform: uppercase;
        color: $color-red;
      }
    }

    .items {
      display: flex;
      align-items: center;

      .navbar__item__userInfo {
        border-left: 2px solid $color-grey-other;
        padding-left: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 20px;
        position: relative;

        &__nomeRole {
          display: flex;
          flex-direction: column;

          &__nome {
            font-size: 12px;
            @include font-primary-semiBold;
          }

          &__role {
            font-size: 11px;
            color: $color-grey-dark;
          }
        }

        .icon {
          font-size: 20px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }

  &__path__wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid $color-grey-other;

    .navbar__path {
      color: $color-blue-link;
      font-family: EuclidSemiBold;
      display: flex;
      align-items: center;

      .navbar__path-utility-div {
        display: flex;
        align-items: center;
      }

      a {
        text-decoration: none;
      }
    }

    .navbar__path__last {
      color: $color-grey-dark;
    }

    .navbar__icon__front {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 30px;
      font-family: EuclidLight;
      color: $color-grey-dark;
    }

    .big-btn {
      background-color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 15px;
      border-radius: 2px;
      border: 0.5px solid $color-grey-light;
      cursor: pointer;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

      &-text {
        margin-left: 10px;
        font-family: EuclidSemiBold;
      }

      &-text,
      &-icon {
        color: $color-grey-dark;
      }
    }
  }
}

.navbar__dropdownIcon {
  color: $color-grey-dark;
  border-radius: 50%;
  padding: 5px;
  &:hover {
    background-color: $color-grey-light;
  }
}
