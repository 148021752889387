@import "../../../../../style/colors.scss";
@import "../../../../../style/typography.scss";

.debitos__container__cabecalho {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-bottom: 50px;

  #debitos__container__cabecalho__mostrar-recebidos {
    justify-self: flex-start;
    flex-direction: row;
    justify-content: left;
    margin-left: 10px;
    span {
      font-size: 14px;
    }
  }

  &__totais {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;

    &--recebido,
    &--por-receber {
      display: flex;
      flex-direction: column;
      width: 180px;
      gap: 5px;

      span:first-child {
        @include font-primary-regular;
        text-transform: uppercase;
        color: $color-grey-dark;
        font-size: 12px;
      }
    }

    &--recebido {
      span:last-child {
        @include font-primary-regular;
        color: rgb(116, 169, 37);
      }

      border-bottom: 5px solid rgb(116, 169, 37);
      padding-bottom: 5px;
    }

    &--por-receber {
      span:last-child {
        @include font-primary-regular;
        color: $color-red;
      }

      border-bottom: 4px solid $color-red;
      padding-bottom: 5px;
    }
  }
}

.debitos__container__receber {
  @include font-primary-regular;
  font-size: 14px;
  text-transform: uppercase;
  color: $color-blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.debitos__container__estado,
.debitos__container__valor,
.debitos__container__receber {
  &--atraso {
    color: $color-red;
  }

  &--pago {
    color: green;
    cursor: default;
  }

  &--isento {
    color: #34a8f1;
    cursor: default;
  }

  &--fte {
    color: #652d85;
    cursor: default;
  }
  &--reciboInterno {
    color: $color-grey-dark;
    cursor: default;
  }
}

.nrParcela {
  @include font-primary-regular;
  background-color: $color-grey-light;
  border-radius: 4px;
  color: $color-dark;
  font-size: 12px;
  padding: 2px;
  margin: 0 1px;
}

.muiIconVerOrcamento {
  color: $color-blue-link;
  padding: 0;
}

.spanCellPago {
  color: green;
}

.cellDebitoAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .botaoTresPontosDebito {
    cursor: pointer;
  }
}

.cellDebitoStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

//Teste

.three-dots-menu {
  position: relative;
  display: inline-block;
}

.dots {
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.dots span {
  display: block;
  width: 4px;
  height: 4px;
  margin: 3px;
  border-radius: 50%;
  background-color: #000;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 120px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 1;
}

.debitos__gerarPdf {
  cursor: pointer;
}

.btn-aplicarDesconto__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.debitos__isento {
  color: #34a8f1;
  @include font-primary-regular;
  font-size: 14px;
}

.debitos__accordion__title {
  @include font-primary-semiBold;
  color: $color-grey-darker;
  font-size: 14px;
}
