@import "../../../../../style/typography.scss";
@import "../../../../../style/util.scss";
@import "../../../../../style/colors.scss";

.text-bold {
  @include font-primary-bold;
}

.botoes-pdf {
  display: flex;
  justify-content: center;
  padding: 30px 0;

  button {
    @include font-primary-semiBold;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
      background-color: $color-grey-dark;

      &:hover {
        background-color: $color-dark;
      }
    }
    &:nth-child(2) {
      margin-right: 10px;
      background-color: $color-orange;

      &:hover {
        background-color: $color-orange-dark;
      }
    }
  }
}

.orcamento-rodape--particular {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &--left {
    text-align: center;
    padding-top: 20px;
    &__text {
      @include font-primary-regular;
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
  }

  &__div {
    padding: 10px 0;
    display: flex;
    gap: 15px;
    text-align: right;
    justify-content: flex-end;
    span {
      @include font-primary-semiBold;
      font-size: 14px;
    }
  }

  &__linha {
    grid-column: 1/-1;
    border-top: 1px solid black;
    &--pequena {
      width: 350px;
      margin-left: auto;
    }
  }
}

.orcamento-nota {
  margin-top: 25px;
  color: $color-grey-darker;
  @include font-secondary-regular;
  font-size: 14px;
}
