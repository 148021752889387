@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.wholeChartContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.chart {
  flex: 4;
  padding: 0 0 15px 0;
  background: #1e3058;

  &__title {
    display: flex;
    gap: 40px;
    flex-direction: column;
    background-color: #1e3058;
    padding: 20px 20px 35px 20px;

    &__title {
      color: #d6d9e0;
      font-size: 34px;
      @include font-primary-bold;
    }

    &__title2 {
      color: #d6d9e0;
      font-size: 32px;
      @include font-primary-regular;
    }

    &__subtitle {
      color: #d6d9e0;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .chartGrid {
    stroke: rgba(228, 225, 225);
  }
}
