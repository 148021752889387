@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.dashboard__middle--dentista__left {
  min-width: 730px;
  display: flex;
  gap: 10px;
}

#avaliacoesplanos--dentista,
#topTratamentos--dentista {
  flex: 1;
}

#dashboard__middle-dentista {
  gap: 15px;
}

.dasboard__middle__ultimasAvaliacoes {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.ultimasAvaliacoes__list-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  margin-top: 15px;
  gap: 10px;

  &__header {
    @include font-primary-semiBold;
    color: $color-grey-dark;
    font-size: 14px;
  }

  &__element {
    @include font-primary-semiBold;
    color: $color-dark;
    font-size: 14px;

    &--link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    &--details {
      color: $color-grey-dark;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
