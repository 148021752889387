.credenciais-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    display: flex;
    gap: 5px;
    align-items: center;

    &__number {
      font-weight: 600;

      &--danger {
        color: crimson;
      }
    }
  }
}

.credenciais__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
