@import "../../style/typography.scss";
@import "../../style/util.scss";

.btn-acoes-avaliacao {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0 0 0 10px;
  letter-spacing: 0.25px;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .mdi {
    margin-right: 4px;
  }
}

.btn-delete {
  font-size: 13px;
  color: crimson;

  > .mdi-delete-empty {
    display: none;
  }

  &:hover {
    // background-color: lighten(crimson, 48%);

    > .mdi-delete-empty {
      display: block;
    }

    > .mdi-delete {
      display: none;
    }
  }

  &:focus {
    box-shadow: 0 0 0 4px lighten(crimson, 40%);
  }
}

.btn-adicionar {
  font-size: 13px;
  color: rgb(116, 169, 37);

  > .css-i4bv87-MuiSvgIcon-root {
    width: 1.2em !important;
    height: 1.2em !important;
  }

  > .mdi-adicionar-empty {
    display: none;
  }

  &:hover {
    > .mdi-adicionar-empty {
      display: block;
    }

    > .mdi-adicionar {
      display: none;
    }
  }

  &:focus {
    box-shadow: 0 0 0 4px lighten(rgb(116, 169, 37), 40%);
  }
}

.acoes {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

//CHECK BOX
/* Customize the label (the container) */
.checkbox_label_container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 20px;
}

/* Hide the browser's default checkbox */
.checkbox_label_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox_label_container:hover input ~ .checkbox_checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_label_container input:checked ~ .checkbox_checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_label_container input:checked ~ .checkbox_checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox_label_container .checkbox_checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.spanOrcamentado {
  color: crimson;
  padding: 10px 0;
}

//Erro Dentes
.erroDentes > div {
  border: 2px solid crimson;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 1em !important;
  height: 1em !important;
}

.elementoOrcamento {
  &__facesEDentes {
    margin-top: 3px;
    color: $color-grey-dark;
  }

  &__span__notToShow {
    text-decoration: line-through;
    color: $color-grey-dark;
  }
}

.elemento-avaliacao-btns-container {
  display: flex;
  justify-content: center;
}
