@import "../../../style/typography.scss";
@import "../../../style/fonts.scss";

//Home administrador nao medico
.stock__warning {
  color: rgb(205, 177, 50);
}

.stockControle {
  &__row {
    display: grid;
    grid-template-columns: 4fr 1fr 2fr;
    row-gap: 5px;
    column-gap: 10px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &:first-child {
      margin-bottom: 15px;
    }

    &__element {
      color: $color-grey-dark;
      font-size: 13px;
    }

    &__titulo {
      color: $color-grey-dark;
    }
  }

  .stock__warning,
  .stock__danger {
    padding: 4px 5px;
    font-size: 13px;
    @include font-primary-semiBold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .stock__warning {
    color: #ffb545;
    background-color: #fdf2e1;
  }
  .stock__danger {
    color: #e77150;
    background-color: #fcdcd0;
  }
}

.stock-sob-controle {
  text-align: center;
  margin-top: 65px;
  @include font-primary-semiBold;
  text-transform: uppercase;
  color: rgb(18, 182, 18);
}

.box-subtitle {
  font-size: 12px;
  @include font-primary-semiBold;
  text-transform: uppercase;
  color: $color-grey-dark;
}
