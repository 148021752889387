@import "../../../../../../../style/typography.scss";

.modalEditarDesconto__container {
  display: flex;
  gap: 15px;
  align-items: center;

  &__inputText > div > input {
    padding: 10px 14px !important;
  }

  &__valor {
    @include font-primary-medium;
    background-color: $color-grey-light;
    padding: 10px 24px;
  }
}

.modalEditarDesconto__dadosOrcamento {
  background-color: $color-grey-light;
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 35px;
  row-gap: 20px;
  padding: 10px;

  &__titulo {
    grid-column: 1/-1;
    text-align: center;
    @include font-secondary-medium;
    border-bottom: 1px dashed $color-grey-dark;
    padding-bottom: 10px;
  }

  &__subtitulo {
    @include font-secondary-medium;
    padding-bottom: 10px;
  }

  &__linha {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  &__linha--right,
  &__valor--right {
    color: $color-blue;
  }

  &__legenda {
    @include font-secondary-regular;
  }

  &__valor {
    @include font-secondary-medium;
  }
}
