.appointment-form {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 450px; // Increased width
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding-right: 10px;

  &__datasContainer {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &.open {
    transform: translateX(0);
  }

  &__content {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
  }

  &__title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__field {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
  }

  &__submit-btn,
  &__close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
  }

  &__submit-btn {
    background-color: rgb(8, 113, 239);
    color: white;

    &:hover {
      background-color: rgb(21, 101, 199);
    }
  }

  &__close-btn {
    background-color: transparent;
    color: rgb(8, 113, 239);
    border: 1px solid rgb(8, 113, 239);

    &:hover {
      border-color: rgb(21, 101, 199);
      color: rgb(21, 101, 199);
    }
  }
}

.appointment-form__errorContainer {
  color: rgb(199, 52, 81);
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

// ////////////////////
// /// /* Card.css */
// .card {
//   background-color: white;
//   border-radius: 8px;
//   border: 1px solid #e5e7eb;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
// }

// .card-header {
//   padding: 1.5rem;
//   border-bottom: 1px solid #e5e7eb;
// }

// .card-title {
//   font-size: 1.125rem;
//   font-weight: 600;
//   line-height: 1.5;
//   margin: 0;
// }

// .card-content {
//   padding: 1.5rem;
// }

// .card-footer {
//   padding: 1.5rem;
//   border-top: 1px solid #e5e7eb;
//   background-color: #f9fafb;
//   display: flex;
//   align-items: center;
// }

// /* ClientAutocomplete.css */
// .autocomplete-wrapper {
//   position: relative;
//   width: 100%;
// }

// .autocomplete-input {
//   width: 100%;
//   padding: 0.5rem;
//   border: 1px solid #e5e7eb;
//   border-radius: 4px;
//   font-size: 1rem;
//   line-height: 1.5;
// }

// .autocomplete-input:focus {
//   outline: none;
//   border-color: #3b82f6;
//   box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
// }

// .autocomplete-suggestions {
//   position: absolute;
//   top: 100%;
//   left: 0;
//   right: 0;
//   margin-top: 4px;
//   max-height: 240px;
//   overflow-y: auto;
//   background-color: white;
//   border-radius: 4px;
//   border: 1px solid #e5e7eb;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   z-index: 50;
// }

// .suggestion-item {
//   padding: 0.5rem 1rem;
//   cursor: pointer;
// }

// .suggestion-item:hover {
//   background-color: #f3f4f6;
// }

// .suggestion-item.focused {
//   background-color: #eff6ff;
//   color: #1e40af;
// }

// .suggestion-highlight {
//   background-color: #fef3c7;
// }

// .suggestion-id {
//   font-size: 0.75rem;
//   color: #6b7280;
//   margin-top: 2px;
// }

// /* AppointmentForm.css */
// .autocomplete-wrapper {
//   position: relative;
//   width: 100%;
// }

// .autocomplete-input {
//   width: 100%;
//   padding: 8px;
//   border: none;
//   border-radius: 4px;
//   font-size: 14px;
//   line-height: 1.5;
// }

// .autocomplete-input:focus {
//   outline: none;
//   box-shadow: 0 0 0 2px #3b82f6;
// }

// .autocomplete-suggestions {
//   position: absolute;
//   top: calc(100% + 4px);
//   left: 0;
//   right: 0;
//   max-height: 240px;
//   overflow-y: auto;
//   z-index: 50;
// }

// .suggestion-item {
//   padding: 8px 16px;
//   cursor: pointer;
// }

// .suggestion-item:hover {
//   background-color: #f3f4f6;
// }

// .suggestion-item.focused {
//   background-color: #eff6ff;
//   color: #1e3a8a;
// }

// .suggestion-highlight {
//   background-color: #fef08a;
// }

// .suggestion-id {
//   font-size: 12px;
//   color: #6b7280;
// }

/* Container styling */
.mui-style-autocomplete {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Input container */
.mui-style-input-container {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.2s ease;
  min-height: 56px;
  padding: 0 14px;
}

.mui-style-input-container:hover {
  border-color: #000;
}

.mui-style-input-container.focused {
  border-color: #1976d2;
  border-width: 2px;
  padding: 0 13px;
}

/* Input field */
.mui-style-input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 16.5px 0;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

.mui-style-input::placeholder {
  color: rgba(0, 0, 0, 0.42);
  opacity: 1;
}

/* Label */
.mui-style-label {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  transition: all 0.2s ease;
  pointer-events: none;
  background-color: #fff;
  padding: 0 4px;
}

.mui-style-input-container.focused .mui-style-label,
.mui-style-input-container.has-value .mui-style-label {
  top: 0;
  transform: translateY(-50%) scale(0.75);
  color: #1976d2;
}

/* Suggestions dropdown */
.mui-style-suggestion {
  padding: 6px 16px; /* Reduced from 8px to 6px vertical padding */
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  min-height: 32px; /* Reduced from 48px to 36px */
  display: flex;
  align-items: center;
}

/* Add padding to the suggestions container */
.mui-style-suggestions {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  padding: 4px 0; /* Added padding to match Material UI */
}

.mui-style-suggestion:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mui-style-suggestion.focused {
  background-color: rgba(25, 118, 210, 0.08);
}

/* Client ID display for duplicates */
.mui-style-client-id {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 8px;
}

/* Highlighted text in suggestions */
.mui-style-highlight {
  background-color: rgba(25, 118, 210, 0.12);
  padding: 2px 0;
}

/* Scrollbar styling */
.mui-style-suggestions::-webkit-scrollbar {
  width: 8px;
}

.mui-style-suggestions::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.mui-style-suggestions::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.mui-style-suggestions::-webkit-scrollbar-thumb:hover {
  background: #555;
}
