@import "../../../../../style/colors.scss";
@import "../../../../../style/typography.scss";
@import "../../../../../style/util.scss";

.marcacoesAdministrativo__clienteImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.linkNoNomeDoCliente {
  text-decoration: none;
  color: rgb(32, 104, 211);
  &:hover {
    text-decoration: underline;
  }
}

.box-escolha-de-dia {
  display: flex;
  margin-bottom: 15px;
  gap: 15px;
  align-items: center;

  select {
    @include font-primary-semiBold;
    border-radius: 4px;
    cursor: pointer;
    padding: 3px 5px;
    font-size: 13px;
    border-color: $color-grey-other;
    outline: $color-grey-other;
  }
}

.nenhumProcedimentoMsg {
  text-align: center;
  margin-top: 20px;
  margin-left: auto;
  font-size: 15px;
}

.marcacoesAdministrativo__nome__medico__span {
  padding: 5px 9px;
  border-radius: 10px;
}

.marcacaoTerminadaBtn,
.marcacaoPresencaBtn {
  padding: 5px 7px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 15px;
  background-color: transparent;
  color: #20d359;
  border: 1px solid #20d359;

  &:hover {
    color: white;
    background: #20d359;
  }
}

.marcacaoPresencaBtn--chegou {
  color: rgb(32, 104, 211);
  border: 1px solid rgb(32, 104, 211);
  &:hover {
    color: white;
    background: rgb(32, 104, 211);
  }
}

.marcacaoPresencaBtn--saiu {
  color: crimson;
  border: 1px solid crimson;
  &:hover {
    color: white;
    background: crimson;
  }
}

.marcacoes-secretaria-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

#ver-atendidos__checkbox-container {
  flex-direction: row;
}

.spanMensagemAtendido {
  color: #20d359;
}

.spanMensagemFaltou {
  color: crimson;
}

.box-title--atendimentosSecretaria {
  font-size: 14px;
  @include font-secondary-medium;
  letter-spacing: 0.7px;
}
