@import "./../../../style/typography.scss";
@import "./../../../style/util.scss";

.new {
  .bottom {
    &-left {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 300px;
      }
    }

    &-right {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      row-gap: 30px;
      ///////////
    }
  }
}

///CHECKBOX ACEITA FACES E MULTIDENTES

.checkbox-tratamento {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.checkbox-tratamento input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-tratamento .toggle {
  position: relative;
  display: block;
  width: 42px;
  height: 13px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.checkbox-tratamento .toggle:before {
  content: "";
  position: relative;
  top: 1px;
  left: 1px;
  width: 40px;
  height: 22px;
  display: block;
  background: #c8ccd4;
  border-radius: 12px;
  transition: background 0.2s ease;
}

.checkbox-tratamento .toggle span {
  position: absolute;
  top: 6px;
  left: 8px;
  width: 24px;
  height: 24px;
  display: block;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(154, 153, 153, 0.75);
  transition: all 0.2s ease;
}

.checkbox-tratamento .toggle span svg {
  margin: 7px;
  fill: none;
}

.checkbox-tratamento .toggle span svg path {
  stroke: #c8ccd4;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 24;
  stroke-dashoffset: 0;
  transition: all 0.5s linear;
}

.checkbox-tratamento input[type="checkbox"]:checked + .toggle:before {
  background: #52d66b;
}

.checkbox-tratamento input[type="checkbox"]:checked + .toggle span {
  transform: translateX(18px);
}

.checkbox-tratamento input[type="checkbox"]:checked + .toggle span path {
  stroke: #52d66b;
  stroke-dasharray: 25;
  stroke-dashoffset: 25;
}

.tratamento__checkbox-label {
  top: 0 !important;
}

.tratamentos-checkboxes__container {
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
}
