@import "../../../../../../style/colors.scss";
@import "../../../../../../style/typography.scss";
@import "../../../../../../style/util.scss";
@import "../../../../../../style/fluxo-atendimento__modals.scss";

#modal__cinquentaPorCento {
  width: 50%;
}

.aprovar-orcamento__modal {
  &__parcela-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
  }

  &__indice-parcela {
    width: 90px;
    & div input {
      height: 0.6em;
    }
  }

  &__datePicker {
    width: 160px;
    & div input {
      height: 0.6em;
    }
  }

  &__valor {
    width: 150px;

    & div input {
      height: 0.6em;
    }
  }

  &__parcela-entradaSpan {
    @include font-primary-regular;
    color: $color-grey-dark;
    font-size: 15px;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 8px;
    margin-bottom: -10px;
    margin-right: 10px;
  }
  &__metodo-pagamento {
    min-width: 130px !important;
  }
}
