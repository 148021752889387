@import "../../../style/typography.scss";
.editorcamento__item {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  height: 70px;

  &--header {
    font-size: 14px;
    @include font-primary-semiBold;

    &Container {
      text-align: center;
    }
  }
}

.editElementoOrcamento__nomeTratamento {
  font-size: 15px;
  @include font-primary-regular;
}
