@import "../../../../../style/typography.scss";

.relatorio__filtros__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left,
  &__right {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__right {
    &__btn {
      padding: 7px 15px;
      border: none;
      border-radius: 2px;
      color: white;
      font-size: 13px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      cursor: pointer;

      &--blue {
        background-color: $color-blue;
      }

      &--green {
        background-color: #55b983;
      }
    }
  }
}

.relatorio__filtros {
  &__texto {
    @include font-primary-bold;
    font-weight: 500;
    letter-spacing: 0.7px;
    font-size: 14px;
  }
}
