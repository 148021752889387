@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.homeContainerAdministrativo {
  margin: 30px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.homeAdministrativo__topContainer {
  display: flex;
  gap: 25px;
}

.homeAdministrativo__bottomContainer {
  display: flex;
  gap: 25px;
}

.dashboard__ola {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0 0 40px;

  &__big {
    @include font-primary-semiBold;
    color: $color-dark;
    font-size: 23px;
    text-transform: capitalize;
  }

  &__small {
    @include font-primary-semiBold;
    color: $color-grey-darker;
    font-size: 16px;
  }
}
