@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";

.dashboardAdministrativoFinanceiro__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboardAdministrativoFinanceiro {
  &__titulo {
    @include font-primary-semiBold;
    font-size: 17px;
    margin-bottom: 15px;
  }

  &__receitasDespesas {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__tituloContainer {
      display: flex;
      align-items: center;
      gap: 5px;

      &__titulo {
        @include font-primary-semiBold;
        text-transform: uppercase;
        font-size: 13px;
      }

      &__iconReceita {
        color: rgb(116, 169, 37);
      }
      &__iconDespesa {
        color: $color-red;
      }
    }

    &__linha1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid $color-grey-dark;
    }

    &__linha2 {
      display: flex;
      justify-content: flex-end;
    }
  }
}
