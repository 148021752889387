@import "../../../../../../style/typography.scss";
@import "../../../../../../style/colors.scss";
@import "../../../../../../style/util.scss";

.single-agendamento {
  margin-top: 20px;
  padding: 10px;
  @include font-primary-regular;

  display: flex;
  flex-direction: row;
  gap: 10px;

  &--column {
    padding: 0 15px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    &:not(:last-child) {
      border-right: 2px solid $color-grey-light;
    }

    &--1 {
      flex: 2;
    }

    &--2 {
      flex: 5;
    }

    &--3 {
      flex: 2;
    }
  }

  &--data {
    &--data {
      font-size: 25px;
      @include font-primary-regular;

      @media only screen and (max-width: 1350px) {
        font-size: 20px;
      }
    }

    &--horas {
      font-size: 13px;
      color: $color-grey-dark;
    }
  }

  &--tratamento {
    align-items: flex-start;
  }

  //util
  &--title {
    font-size: 13px;
    color: $color-grey-dark;
    text-align: left;
    @media only screen and (max-width: 1350px) {
      font-size: 12px;
    }
  }

  &--text {
    font-size: 16px;
    @include font-primary-semiBold;

    @media only screen and (max-width: 1350px) {
      font-size: 14px;
    }
  }
}
