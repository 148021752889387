@import "../../style/colors.scss";
@import "../../style/typography.scss";

.financeiro__container {
  margin: 30px;
}

.financeiro__container__cabecalho {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  #financeiro__container__cabecalho__mostrar-recebidos {
    justify-self: flex-start;
    flex-direction: row;
    justify-content: left;
    margin-left: 10px;
    span {
      font-size: 14px;
    }
  }

  &__totais {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;

    &--titulos {
      @include font-primary-regular;
      text-transform: uppercase;
      color: $color-grey-dark;
      font-size: 12px;
    }

    &__firstContainer {
      display: flex;
      flex-direction: column;
      width: 235px;
      gap: 5px;

      &--saldo {
        border-bottom: 3px solid $color-blue-link;
        padding-bottom: 2px;
      }

      &--recebido {
        border-bottom: 3px solid rgb(116, 169, 37);
        padding-bottom: 2px;
      }

      &--por-receber {
        border-bottom: 3px solid $color-red;
        padding-bottom: 2px;
      }
    }

    &--recebido,
    &--por-receber,
    &--saldo {
      display: flex;
      flex-direction: column;
    }

    &--recebido {
      &--valor_principal {
        color: rgb(116, 169, 37);
      }
    }

    &--por-receber {
      &--valor_principal {
        color: $color-red;
      }
    }

    &--saldo {
      &--valor_principal {
        color: $color-blue-link;
      }
    }

    &--separador {
      font-size: 30px;
      color: $color-grey-dark;
    }

    &--icon_div {
      display: flex;
      align-items: center;
      gap: 5px;

      &--receita {
        color: rgb(116, 169, 37);
      }

      &--despesa {
        color: $color-red;
      }
    }

    &--texto {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &--info {
        font-size: 12px;
        color: $color-grey-dark;
      }
    }
  }
}

.financeiro__container__receber {
  text-transform: uppercase;
  color: $color-blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.financeiro__container__estado,
.financeiro__container__valor,
.financeiro__container__receber {
  &--atraso {
    color: $color-red;
  }

  &--pago {
    color: green;
    cursor: default;
  }
}

.nrParcela {
  @include font-primary-regular;
  background-color: $color-grey-light;
  border-radius: 4px;
  color: $color-dark;
  font-size: 12px;
  padding: 2px;
  margin: 0 1px;
}

.muiIconVerOrcamento {
  color: $color-blue-link;
  padding: 0;
}

.spanCellPago {
  color: green;
}
.spanCellAtraso {
  color: crimson;
}

.cellDebitoAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .botaoTresPontosDebito {
    cursor: pointer;
  }
}

.total-previsto {
  @include font-primary-regular;
  color: $color-grey-dark;
  font-size: 12px;
  text-align: right;
}

.acoes__icons {
  display: flex;
  align-items: center;
  gap: 7px;

  &__ver {
    color: $color-blue;
    cursor: pointer;
  }

  &__cancelar {
    color: $color-red;
    font-size: 8px;
    cursor: pointer;
  }
}

.link-cliente-receita {
  text-decoration: none;
  cursor: pointer;
  color: $color-blue;
  &:hover {
    text-decoration: underline;
  }
}

.svgDFE__comunicado {
  width: 20px;
  fill: green;
}

.single__abas-principais--financeiro {
  gap: 150px;
}
