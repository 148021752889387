@import "../../../style//typography.scss";
@import "../../../style//colors.scss";

.single-user {
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px;
    gap: 20px;

    &__title {
      @include font-primary-bold;
      font-size: 16px;
    }

    &__subtitle {
      @include font-primary-regular;
      font-size: 14px;
      color: $color-grey-dark;
      margin-bottom: 35px;
    }

    &__pass-reset__container,
    &__info__container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    //Pass reset container
    &__pass-reset__container {
      &__input-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-size: 12px;
          @include font-primary-semiBold;
          margin-top: 7px;
        }
        input {
          @include font-primary-regular;
          padding: 10px;
          border-radius: 4px;
          font-size: 14px;
          border: 1px solid $color-grey-dark;
          &:focus {
            border: 1px solid $color-blue;
          }
        }
      }
    }

    //Button
    &__btn {
      padding: 10px;
      @include font-primary-regular;
      font-size: 14px;
      color: white;
      background-color: $color-blue;
      border-radius: 4px;
      border: none;
      width: 35%;
      align-self: end;
      margin-top: 35px;
      cursor: pointer;
    }
  }
}

.alertaSingleUserContainer {
  margin: 20px;
  text-align: center;
  @include font-primary-semiBold;
  font-size: 16px;
  text-transform: uppercase;
}

.perfil__horarioAtendimento__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 20px;
}
