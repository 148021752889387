@import "../../style/typography.scss";
@import "../../style/util.scss";
@import "../../style/colors.scss";

.datatable {
  height: 700px;
  padding: 30px;
  margin: 30px;

  @media only screen and (max-width: 1100px) {
    padding: 10px;
    margin: 0;
  }

  .datagrid {
    border: none;

    .MuiDataGrid-columnHeaders {
      @include font-secondary-semiBold;
      color: $color-dark;
      font-size: 13px;
    }

    .MuiDataGrid-row {
      @include font-primary-regular;
      color: $color-grey-dark;
      font-size: 14px;
      padding: 2px 0;
    }

    .MuiDataGrid-footerContainer {
      padding: 10px 0 25px 0;
      @include font-secondary-regular;
    }

    .cellAction {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__add-button-div {
    margin: 30px 20px 30px 0;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }

  .cellWithImg {
    display: flex;
    align-items: center;
  }
}

.cellImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  cursor: pointer;

  @media only screen and (max-width: 1100px) {
    width: 30px;
    height: 30px;
  }
}

.fotoModal {
  max-width: 100%;
  max-height: 100%;
}

//Div de procura
.search__datatable__container {
  display: flex;
  padding: 2px;
  @include font-primary-regular;
  border: 1px solid $color-grey-dark;
  border-radius: 4px;

  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }

  input[type="search"]::placeholder {
    color: #bbb;
  }

  span {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    opacity: 0.7;
  }

  span:hover {
    opacity: 1;
  }

  span:focus,
  input:focus {
    outline: none;
  }
}
