@import "../../../../../style/colors.scss";
@import "../../../../../style/typography.scss";
@import "../../../../../style//util.scss";

.historico__colapse-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__consulta {
    &__row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 5px 10px;
      border: 0.5px solid $color-grey-light;

      &--header {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 5px 10px;
        @include font-primary-semiBold;
        font-size: 13px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 14px;
        &--tratamento {
          color: $color-blue;
          @include font-primary-semiBold;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 2fr repeat(4, 1fr);
    padding: 5px 10px;
    border: 0.5px solid $color-grey-light;

    &--header {
      display: grid;
      grid-template-columns: 1fr 2fr repeat(4, 1fr);
      padding: 5px 10px;
      @include font-primary-semiBold;
      font-size: 13px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 14px;

      &:first-child {
        font-size: 13px;
        color: $color-dark;
        @include font-primary-semiBold;
      }

      &--tratamento {
        color: $color-blue;
        @include font-primary-semiBold;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.evolucaoItemContainer {
  display: flex;
}

.evolucao-btns {
  display: flex;
  flex-direction: column;
  width: 5%;
}

.evolucaoClienteSolo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 95%;

  &__container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.evolucao-datas {
  font-size: 12px;
  color: #555555;
  @include font-primary-regular;
}

.evolucao-descricao {
  font-size: 15px;
}

.adicionarEvolucaoButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  margin-top: 30px;
}

.historicoStatus {
  padding: 5px 10px;
  border-radius: 17px;
  @include font-primary-medium;
  align-self: flex-start;
  font-size: 14px;

  &Concluido {
    color: #12d453;
    background-color: #e5faec;
  }

  &Transformado {
    color: #11cce6;
    background-color: #ecfbfd;
  }

  &INTERROMPIDO {
    color: #eb3c5f;
    background-color: #f1a7b679;
  }
}

.historico__noDataMessage {
  text-align: center;
  margin-top: 40px;
  @include font-primary-semiBold;
  font-weight: 700;
}
