@import "../../../style/typography.scss";
@import "../../../style/fonts.scss";

.home {
  display: flex;

  .homeContainer {
    flex: 8;

    .dashboard__top,
    .dashboard__middle {
      display: flex;
    }

    .dashboard__middle {
      justify-content: space-between;
      gap: 10px;
      margin: 0 30px 30px 30px;
    }

    .dashboard__top {
      gap: 15px;
      margin: 20px 30px 20px 30px;
    }

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      margin: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .dashboard__middle div {
      flex: 3;
    }

    .dashboard__right {
      flex: 2;
      display: flex;
      padding: 0;
      flex-direction: column;

      &__top {
        flex: 5;
        display: flex;
        padding: 20px;

        &-left {
          .info-planos-tratamento {
            display: flex;
            flex-direction: column;
          }
        }

        .urgencia {
          color: crimson;
        }

        &-left,
        &-right {
          flex: 5;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      hr {
        color: $color-grey-dark;
        margin: 0 20px;
        border-top: 2px;
      }

      &__bottom {
        flex: 5;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .dasboard__middle__todaysAppointments {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &-separador {
        display: flex;
        gap: 10px;

        .nr-marcacoes {
          flex: 2;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          & span {
            font-size: 70px;
            color: $color-grey-dark;
          }
        }

        .detalhes-marcacoes {
          flex: 6;
          display: flex;
          flex-direction: column;
          gap: 10px;

          &-box {
            display: flex;
            background-color: $color-grey-medium;
            border-radius: 4px;
            padding: 10px 10px;
            gap: 20px;

            &-text {
              display: flex;
              flex-direction: column;
              gap: 1px;

              &1 {
                font-size: 8px;
                color: $color-grey-dark;
              }
              &2 {
                font-size: 12px;
                color: $color-dark;
                font-family: EuclidBold;
              }
              &1::first-letter,
              &2::first-letter {
                text-transform: uppercase;
              }
            }

            &-time {
              font-size: 11px;
              color: $color-grey-dark;
              align-self: center;
            }
          }
        }
      }
    }
  }
}

.lucro-description {
  font-size: 13px;
  color: $color-grey-dark;
  text-transform: capitalize;
}

.total-patients,
.big-numbers {
  font-size: 45px;
  color: $color-grey-dark;
}

.button-more-div {
  max-height: 22px;
  width: 100px;
  display: flex;
  background-color: $color-grey-light;
  border-radius: 20px;
  padding: 6px 9px 6px 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-dark;
  }

  .button-more {
    color: $color-dark;
    font-size: 14px;
    font-family: EuclidBold;
  }

  .more-arrow {
    width: 20px;
    height: 20px;
    background-color: $color-blue;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .more-arrow--urgencia {
    background-color: crimson;
  }
}

.medium-gray-text {
  color: $color-grey-dark;
  font-size: 14px;
  text-transform: capitalize;
}

.modalUrgenciaList {
  display: grid;
  grid-template-columns: 55% 25% 20%;
  gap: 15px;
  padding: 10px 20px;
  align-items: center;

  a {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }

  .btn-atendido {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .urgencia-list-title {
    @include font-primary-semiBold;
    color: $color-dark;
  }
}

.modalAvaliacoesPorEfetuarList {
  display: grid;
  grid-template-columns: 55% 20% 25%;
  gap: 15px;
  padding: 10px 20px;
  align-items: center;

  a {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }
  .urgencia-list-title {
    @include font-primary-semiBold;
    color: $color-dark;
  }
}

.link__dashboard {
  text-decoration: none;
}

.elemento-lista-bg-escuro {
  background-color: $color-grey-other;
}
