.newTaskContainer {
  display: grid;
  column-gap: 15px;
  row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

.datepicker-tasks > div > input {
  padding: 9.5px 14px;
}
// .takes2 > label,
// .takes4 > label {
//   top: 0 !important;
// }
.takes2 {
  grid-column: span 1;
}

.takes4 {
  grid-column: span 2;
}

.takes5 {
  grid-column: span 3;
}

.takesAll {
  grid-column: 1 / -1;
}
