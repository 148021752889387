@import "../../../../../../style/typography.scss";

.adicionarEvolucaoContainer__datepickers {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modalEvolucaoErroContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: crimson;
  @include font-primary-regular;
}
