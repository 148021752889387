.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #0c4f83;
  border-radius: 4px;
  background: #105b95;
  color: white;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  width: 60%;
  margin-top: 1.5rem;
  font-size: 1rem;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #0ab7c6;
  border-color: #0ab7c6;
}

.button--inverse {
  background: transparent;
  color: #0ab7c6;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #0ab7c6;
}

.button--danger {
  background: #105b95;
  border-color: #0c4f83;
}

.button--danger:hover,
.button--danger:active {
  background: #105b95;
  border-color: #0c4f83;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
