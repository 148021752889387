.form-control {
  margin: 0.7rem 0;
  width: 60%;
}

/* .form-control label, */
.form-control input,
.form-control textarea {
  display: block;
}

/* .form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
} */

.form-control input,
.form-control textarea {
  font: inherit;
  border: 1px solid #c0b7b7;
  /* background: #f8f8f8; */
  padding: 0.5rem 0.75rem;
  width: 100%;
  border-radius: 7px;
  box-sizing: border-box;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  /* background: #ebebeb; */
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
  max-width: 400px;
  word-break: normal;
  padding: 0.2rem 0.4rem;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
