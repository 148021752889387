@import "../../../../../../style/colors.scss";
@import "../../../../../../style/typography.scss";
@import "../../../../../../style/util.scss";
@import "../../../../../../style/fluxo-atendimento__modals.scss";

#modal__aprovar-orcamento {
  width: 60%;
  min-width: 700px;
}

.aprovar-orcamento__modal {
  &__regras-aprovacao {
    @include font-primary-regular;
    color: $color-grey-dark;
    margin: 10px 0 20px 0;
  }

  &__procedimentos__container {
    margin-top: 15px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
      display: grid;
      grid-template-columns: 4fr repeat(3, 2fr) 1fr;
      border-bottom: 1px solid $color-grey-other;
      padding: 7px 0;
      font-size: 17px;

      &--particular {
        grid-template-columns: 4fr repeat(2, 2fr) 1fr;
      }

      &__title {
        color: $color-grey-dark;
        @include font-primary-semiBold;
        font-size: 14px;
      }

      &__out {
        text-decoration: line-through;
        text-decoration-color: crimson;
        color: $color-grey-dark;
      }

      &__X {
        color: rgb(187, 49, 77);
        cursor: pointer;
        font-weight: 600;
        &:hover {
          color: crimson;
        }
      }

      &__plus {
        color: rgb(42, 219, 42);
        cursor: pointer;
        font-weight: 600;
        &:hover {
          color: rgb(47, 172, 47);
        }
      }
    }
  }

  &__descricao-container {
    @include font-primary-regular;
    margin-bottom: 30px;
  }

  &__entrada-container {
    margin-top: 30px;
  }

  &__valor-orcamento-container {
    margin-top: 20px;
    @include font-primary-regular;
  }

  &__valor-orcamento-span {
    @include font-primary-semiBold;
  }

  &__nrParcelas {
    width: 35px;
    border: none;
    border-bottom: 1px solid black;
    margin: 0 7px;
  }

  &__saldo {
    @include font-primary-semiBold;
  }
}

.parcelarOrcamentoDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .parcelarOrcamento {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }
  .parcelarOrcamentoDivHorizontal {
    display: flex;
  }
}

.aprovarOrcamento__parcelasCredencial__container {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 65% 35%;
  column-gap: 20px;

  &__item h2 {
    @include font-primary-regular;
    font-size: 20px;
  }
}

.modalAprovar__icon {
  max-width: 35px;

  &--add {
    stroke: rgb(129, 202, 20);
  }

  &--remove {
    stroke: crimson;
  }

  &__container {
    justify-content: flex-end;
  }
}
