// @import "../../../style/colors.scss";
// @import "../../../style/typography.scss";
// @import "../../../style/util.scss";

#orcamentos-por-aprovar__modal {
  width: 60%;
}

.orcamentos-por-aprovar__modal__ {
  &container {
    height: 400px;
  }

  &cabecalho {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
  }
}
