@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.modalNotificarTitulo {
  @include font-primary-semiBold;
  display: block;
}

.modalNotificarSubmitBtn,
.modalNotificarCancelBtn {
  @include font-primary-semiBold;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  width: 70px;
  text-align: center;
}

.modalNotificarSubmitBtn {
  background-color: $color-blue;
  color: white;
  border: 2px solid $color-blue;

  &:hover {
    background-color: #124b7a;
    color: white;
  }
}

.modalNotificarCancelBtn {
  border: 2px solid $color-grey-dark;
  color: $color-grey-dark;
  margin-right: 10px;

  &:hover {
    background-color: $color-grey-dark;
    color: white;
  }
}

.modalNotificarSelect {
  @include font-primary-regular;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  width: 250px;
  margin-top: 5px;
  margin-bottom: 30px;
}

.modalNotificarBtns {
  width: 100%;
  text-align: right;
  justify-items: flex-end;
}
