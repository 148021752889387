@import "../../style/typography.scss";
@import "../../style/colors.scss";

.new {
  display: flex;
  width: 100%;

  .newContainer {
    flex: 8;

    .logs-container {
      padding: 20px;
      max-height: 800px;
      overflow-y: auto;

      .log-item {
        padding: 16px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background: white;
        margin-bottom: 16px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        .log-header {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .log-timestamp {
            font-size: 14px;
            color: #666;
          }

          .log-user {
            font-weight: 500;
          }
        }

        .log-content {
          display: flex;
          gap: 12px;

          .log-icon {
            width: 20px;
            height: 20px;

            &.create {
              color: #22c55e;
            }
            &.edit {
              color: #3b82f6;
            }
            &.delete {
              color: #ef4444;
            }
          }

          .log-details {
            flex: 1;

            .log-description {
              color: #333;
            }

            .log-changes {
              margin-top: 8px;
              background: #f9fafb;
              padding: 12px;
              border-radius: 4px;
              font-size: 14px;

              .change-item {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 8px;
                margin-bottom: 4px;

                .change-old {
                  text-decoration: line-through;
                  color: #666;
                }

                .change-new {
                  color: #333;
                }
              }
            }
          }
        }
      }
    }
  }
}

.logsHeader {
  padding: 10px 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  align-items: center;
}

.logsNoResults {
  &__container {
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  &__message {
    @include font-primary-regular;
    color: $color-grey-dark;
    font-size: 20px;
  }

  &__icon {
    font-size: 40px !important;
    color: $color-grey-darker;
  }
}

.logs__searchButtons {
  &__container {
    display: flex;
    gap: 15px;
    text-transform: uppercase;
  }

  &__btn {
    display: flex;
    align-items: center;
    padding: 7px 15px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
  }

  &__searchBtn {
    gap: 5px;
    background-color: $color-blue;

    &:hover {
      background-color: #154d7a;
    }
  }

  &__cancelBtn {
    background-color: $color-grey-dark;

    &:hover {
      background-color: $color-grey-darker;
    }
  }
}
