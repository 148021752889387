@import "../../../../../../style/typography.scss";
@import "../../../../../../style/colors.scss";

.atestado-modal__body-container {
  display: flex;
  flex-direction: column;
  gap: 25px;

  &__row {
    display: flex;
    gap: 10px;
    flex-direction: column;

    &--horizontal {
      display: flex;
      gap: 10px;
    }
  }
}

.atestado-modal__subtitulo {
  font-size: 14px;
  @include font-primary-regular;
  color: $color-grey-dark;
}

.dataAtestado__datePicker {
  max-width: 180px;
}

.nrDiasAtestado__datePicker {
  height: 55px;
}
