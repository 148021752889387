@import "../../../style/typography.scss";
@import "../../../style/util.scss";

.prescricao-sugestoes-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 260px;
  background-color: $color-grey-light;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  overflow-y: scroll;

  .prescricao-sugestoes-row {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #c5ccd7;

    .prescricao-sugestoes {
      flex: 7;
    }

    .prescricao-sugestoes-buttons {
      flex: 2;
      display: flex;
      gap: 5px;
      flex-direction: row;
      justify-content: flex-end;

      .prescricao-sugestoes-add-btn {
        border-radius: 4px;
        width: 75px;
        padding: 5px 5px;
        background-color: $color-orange;
        @include font-primary-semiBold;
        text-transform: uppercase;
        font-size: 12px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: $color-orange-dark;
        }
      }

      .prescricao-sugestoes-delete-btn {
        border-radius: 4px;
        padding: 5px 5px;
        background-color: crimson;
        @include font-primary-semiBold;
        text-transform: uppercase;
        font-size: 12px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: rgb(161, 31, 57);
        }
      }
    }
  }
}

.prescricao-sugestoes-pesquisa {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;

  .prescricao-input-pesquisa {
    flex: 1;
    max-width: 80%;
    margin: 0;
    border-radius: 4px;
    padding: 5px 3px;
    border: 1px solid $color-grey-medium;
    font-size: 16px;
  }
}
