@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

#historicoSaidasModal__modal {
  width: 80vw;
}

.historico__dataFilter__container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
  justify-content: flex-end;

  &__verTodas {
    color: white;
    background-color: $color-blue;
    padding: 10px 15px;
    @include font-primary-semiBold;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    &:hover {
      background-color: $color-blue-link;
    }
  }
}
