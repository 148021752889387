@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";
@import "../../../../style/fluxo-atendimento__modals.scss";

.avaliacoes__modal {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
}

.ver-avaliacao__modal {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
}
