@import "../../../../../style/typography.scss";

.cardRelatorio {
  display: flex;
  border-radius: 10px;
  padding: 15px 20px;
  flex-direction: column;
  gap: 5px;
  border: 1px solid $color-grey-light;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &__titulo {
    @include font-primary-semiBold;
    font-size: 12px;
  }

  &__valor {
    @include font-primary-bold;
    font-size: 18px;
  }
}
