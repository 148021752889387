@import "../../../style/colors.scss";

.modalImportarXlsStock {
  &__adicionarComoDespesa {
    &__container {
      display: grid;
      column-gap: 15px;
      row-gap: 25px;
      grid-template-columns: repeat(4, 1fr);

      &__span1 {
        grid-column: span 1;
      }

      &__span2 {
        grid-column: span 2;
      }

      &__span3 {
        grid-column: span 3;
      }

      &__despesaPagaContainer {
        display: flex;
        align-items: center;
        gap: 7px;
      }
    }
  }

  &__spanModeloXlsx {
    margin-top: 5px;
    font-weight: 600;
    a {
      text-decoration: none;
      color: $color-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__nomeFicheiro {
    font-size: 16px;
    font-weight: 600;
    color: $color-blue;
  }
}

.stockImport2 {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
  &__item {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 2fr 3fr 2fr 1.5fr 1.5fr 2fr;
    column-gap: 10px;
  }
}
