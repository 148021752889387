@import "../../../style/colors.scss";
@import "../../../style/typography.scss";
@import "../../../style//util.scss";

.single__abas-principais--cliente {
  justify-content: space-between;
}
.single {
  display: flex;
  width: 100%;
  background: $color-background-blue;

  &__abas-principais {
    margin: 0;
    padding: 15px 50px;
    display: flex;
    background-color: white;
    border-bottom: 2px solid #e2e8f2;

    &__aba {
      @include font-secondary-semiBold;
      font-size: 14px;
      cursor: pointer;
      color: $color-grey-dark;

      &:hover {
        color: $color-blue;
        text-decoration: underline;
      }

      &--active {
        color: $color-blue;
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.singleContainer {
  flex: 8;
  .singleContainer__all {
    margin: 20px 30px;
    max-width: 100%;

    //Ficheiros e caixa axo eu
    &--contas-correntes {
      display: flex;
      flex-direction: column;
      padding: 20px 5px;
      height: 290px;
    }

    // ///////////////////////////////////////////
    &--files {
      display: flex;
      flex-direction: column;
      padding: 20px 5px;
      max-height: 354px;

      .files-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        span:first-child {
          @include font-primary-bold;
          font-size: 14px;
          color: $color-dark;
        }

        .saveFiles {
          display: flex;
          align-items: center;

          span {
            @include font-primary-bold;
            font-size: 14px;
            color: green;
            cursor: pointer;
            text-transform: uppercase;

            &:hover {
              text-decoration: underline;
              text-decoration-thickness: 1.5px;
            }
          }

          &-icon {
            color: green;
            font-size: 16px;
            margin-right: 4px;
          }
        }

        .addFiles {
          display: flex;
          align-items: center;
          span {
            @include font-primary-bold;
            font-size: 14px;
            color: $color-blue;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
              text-decoration-thickness: 1.5px;
            }
          }

          &-icon {
            color: $color-blue;
            font-size: 16px;
            margin-right: 4px;
          }
        }
      }

      .files-loaded {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;

        span {
          font-size: 12px;
          @include font-primary-semiBold;
          color: $color-grey-dark;
          word-break: break-all;

          &:hover {
            text-decoration: underline;
            text-decoration-color: red;
            text-decoration-thickness: 2px;
            cursor: pointer;
          }
        }
      }

      .fileLoadingSpinnerDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
      }

      .fileValidityError {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        color: crimson;
        font-size: 15px;
        @include font-primary-semiBold;
      }

      .files-list {
        display: flex;
        flex-direction: column;
        padding: 20px;
        max-height: 300px;
        overflow-y: scroll;

        .no-file-span {
          font-size: 16px;
          color: $color-grey-dark;
          text-align: center;
          @include font-primary-semiBold;
        }

        .file-info {
          display: flex;
          justify-content: space-between;
          border: 0.5px solid $color-grey-other;
          padding: 15px 15px;
          border-radius: 4px;
          margin-bottom: 2px;

          // -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

          .file-name {
            display: flex;
            align-items: center;
            margin-right: 15px;

            a {
              text-decoration: none;
            }

            .file-icon,
            span {
              color: $color-grey-dark;
              font-size: 14px;
              @include font-primary-semiBold;

              &:hover {
                text-decoration: underline;
                text-decoration-color: $color-grey-dark;
                text-decoration-thickness: 1.5px;
              }
            }
            span {
              word-break: break-all;
            }

            .file-icon {
              font-size: 15px;
              margin-right: 10px;
            }
          }

          .file-actions {
            display: flex;
            align-items: center;

            &-icon,
            span {
              color: $color-grey-dark;
              font-size: 13px;
              @include font-primary-semiBold;
            }

            &-icon {
              margin-left: 7px;
              font-size: 20px;
              cursor: pointer;

              &:hover {
                color: red;
              }
            }
          }
        }
      }
    }
  }
}

.css-19kzrtu {
  padding: 0 24px !important;
}

/* Files area */
.filesArea {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  position: relative;
  margin: 40px;

  h2 {
    text-align: center;
    margin-bottom: 12px;
  }
}

.abaFicheiros {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 10px 20px;
}

.h2AnexarFicheiros {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px;
}

.tableLink {
  color: $color-blue-link;
  text-decoration: none;
  @include font-primary-semiBold;

  &:hover {
    text-decoration: underline;
  }
}

.container-outros {
  background-color: red;
  display: flex;
  margin: 0 20px;

  flex-direction: column;
  height: 400px;

  &-tabs {
    padding: 4px;
    border-radius: 4px;
    background-color: $color-grey-light;
    display: flex;
    align-self: flex-start;

    .tab-button {
      padding: 7px 30px;
      @include font-primary-semiBold;
      font-size: 12px;
      text-align: center;
      color: $color-grey-dark;
      border-radius: 4px;
      cursor: pointer;

      &--active {
        color: $color-blue-dark;
        background-color: $color-white;
      }
    }
  }

  &-tabs--Container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: $color-grey-light;
    margin-top: 15px;
    height: 330px;
    gap: 15px;

    .tabs-container-content {
      margin: 0 20px;
      max-height: 290px;
      overflow-y: scroll;
    }

    .tabs-container-top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid $color-grey-other;
      padding: 15px 30px;

      // &-title {
      //   font-size: 12px;
      //   @include font-primary-semiBold;
      //   color: $color-dark;
      // }

      &-button {
        background-color: $color-white;
        padding: 10px 20px;
        border: none;
        border-radius: 2px;
        font-size: 12px;
        @include font-primary-semiBold;
        color: $color-grey-dark;
        cursor: pointer;
        border: 1px solid $color-grey-other;
        border-radius: 4px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
