@import "../../../../style/typography.scss";
@import "../../../../style/colors.scss";

.MenuItemNotifications {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom: 1px solid $color-grey-other !important;

  &__closeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-primary-semiBold;
    color: $color-red;
    font-size: 20px;
    margin-left: 12px;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: $color-blue-link;

    &:hover {
      transform: scale(1.01);
      background-color: $color-grey-light;
    }
  }

  &__message {
    @include font-primary-regular;
    padding: 10px 5px;
    font-size: 14px;
  }
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: none !important;
}

.menuNotificacao__cabecalho {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid $color-grey-other;

  &__titulo {
    @include font-primary-semiBold;
    font-size: 25px;
    color: $color-dark;
  }

  &__limparTudo {
    @include font-primary-regular;
    font-size: 15px;

    &__container {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      color: $color-blue-link;
    }
  }
}

.menuNotificacao__cabecalho__limparTudo__container:hover
  .menuNotificacao__cabecalho__limparTudo {
  text-decoration: underline;
}

.menuNotificacao__cabecalho__limparTudo__container:hover
  .menuNotificacao__cabecalho__limparTudo,
.menuNotificacao__cabecalho__limparTudo__container:hover
  .menuNotificacao__cabecalho__limparTudo__icon {
  color: $color-blue-dark;
}
