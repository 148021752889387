@import "../../../../../style/colors.scss";
@import "../../../../../style/typography.scss";
@import "../../../../../style//util.scss";

.singleContainer__fluxo-atendimento {
  display: flex;
  flex-direction: column;
  height: 400px;
  max-width: 100%;

  &-tabs {
    padding: 4px;
    border-radius: 4px;
    background-color: $color-grey-light;
    display: flex;
    align-self: flex-start;

    .tab-button {
      padding: 7px 30px;
      color: $color-grey-dark;
      font-size: 12px;
      @include font-secondary-medium;
      border-radius: 4px;
      cursor: pointer;

      &--active {
        color: $color-blue-dark;
        background-color: $color-white;
      }
    }
  }

  &-tabs--Container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: $color-grey-light;
    margin-top: 15px;
    height: 330px;
    gap: 15px;

    .tabs-container-content {
      margin: 0 20px;
      max-height: 290px;
      overflow-y: scroll;
    }

    .tabs-container-top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid $color-grey-other;
      padding: 15px 30px;

      // &-title {
      //   font-size: 12px;
      //   @include font-primary-semiBold;
      //   color: $color-dark;
      // }

      &-button {
        background-color: $color-white;
        padding: 10px 20px;
        border: none;
        border-radius: 2px;
        font-size: 12px;
        @include font-secondary-semiBold;
        color: $color-grey-dark;
        cursor: pointer;
        border: 1px solid $color-grey-other;
        border-radius: 4px;
      }
    }
  }
}
