@import "../../../../style/colors.scss";

.dashboard__middle__topTreatments {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .topTreatments {
    overflow: hidden;
    height: 179px;
    max-width: 367px;
    width: 367px;
  }

  ol {
    margin-left: 0;
    margin-top: 10px;
    padding-left: 0;

    li {
      color: $color-dark;
      font-size: 14px;
      font-family: EuclidRegular;
      padding: 6px 5px;
      border-radius: 4px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    li:not(:last-child) {
      margin-bottom: 2px;
    }
  }
}
