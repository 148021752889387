@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";
.adicionar-despesa__modal__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  column-gap: 10px;

  &--span1 {
    grid-column: span 1;
  }

  &--span2 {
    grid-column: span 2;
  }

  &__textarea {
    grid-column: 1/-1;
    border-radius: 4px;
    resize: none;
  }

  &__checkbox-pago__container {
    display: flex;
    gap: 8px;
    align-items: center;
    @include font-primary-regular;
    margin: 25px 0 25px 5px;
    input[type="checkbox"] {
      cursor: pointer;
    }
  }

  &__checkbox-recorrente__container {
    display: flex;
    gap: 8px;
    align-items: center;
    @include font-primary-regular;
    margin: 0px 0 0px 5px;
    input[type="checkbox"] {
      cursor: pointer;
    }
  }

  &__row-despesa-recorrente {
  }
}
.adicionar-despesa__modal__pagamento {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  align-items: center;
}
.customInputPlusSign {
  color: green;
  margin-left: 10px;
  font-size: 33px;
  cursor: pointer;
}

.anexar-comprovativo {
  display: flex;
  justify-content: center;
  align-items: center;
  @include font-primary-semiBold;
  font-size: 18px;
  color: $color-blue-link;
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.despesas--regras-ficheiro {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.despesas--comprovativo-selecionado {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  @include font-primary-semiBold;
}

.modal-mensagem-de-erro {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  color: crimson;
  @include font-primary-regular;

  & > span {
    max-width: 700px;
    text-align: center;
  }
}

.verComprovativoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  padding-top: 20px;

  & span {
    @include font-primary-semiBold;
  }
}
