@import "../../../../style/typography.scss";

.fc .fc-timegrid-slot {
  height: 3em !important;
}
.fc-timegrid-event-harness {
  overflow-y: hidden;
}

.fc-event-main {
  padding: 3px 5px 6px 5px !important;
}

.fc-event {
  border-radius: 5px;
  border-style: double;
  border-width: medium;
  overflow-y: hidden;
}

.fc-header-toolbar {
  margin: 0 !important;
  padding: 15px;
  @include font-secondary-medium;
}

.fc-toolbar-title {
  font-size: 16px !important;
  color: rgb(8, 113, 239);
}

.fc-timegrid-slot-label-frame {
  text-align: center;
}

.fc-timegrid-slot-label-cushion {
  font-size: 14px;
  font-weight: 600;
}

.fc-scrollgrid-sync-inner {
  padding: 5px;
}

.fc-button-primary {
  background-color: transparent !important;
  color: rgb(8, 113, 239) !important;
  border: none !important;
}

.fc-next-button,
.fc-prev-button {
  border-radius: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 2.2em;
  width: 2.2em;
}

.fc-next-button:hover,
.fc-prev-button:hover {
  background-color: $color-grey-light !important;
}

thead {
  @include font-secondary-medium;
}

.fc-today-button {
  cursor: pointer;
}

.tooltip__time {
  @include font-primary-regular;
  font-size: 14px;
}

.tooltip__title {
  @include font-secondary-medium;
  font-size: 14px;
}
