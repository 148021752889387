//TO DO
@import "../../../../style/typography.scss";
@import "../../../../style/fonts.scss";

.tasks__container {
  margin: 30px;
}
.todo__tabs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__adicionar-tarefa {
    // display: flex;
    // color: white;
    // background-color: $color-blue;
    // font-size: 30px;
    // border-radius: 50%;
    // border: 2px solid $color-blue;
    // height: 45px;
    // width: 45px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // cursor: pointer;
    // transition: all 0.2s;
    // position: relative;
    margin-right: 25px;

    // &:hover {
    //   background-color: white;
    //   color: $color-blue;
    // }
  }
}

.todo__tabs-container {
  display: flex;
  gap: 25px;
  align-items: center;

  &__tab {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      text-decoration-color: $color-blue;
      text-decoration-thickness: 2px;
    }
  }

  &__tab--active {
    text-decoration: underline;
    text-decoration-color: $color-blue;
    text-decoration-thickness: 2px;
  }
}

.todo__list {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 4fr repeat(3, 2fr) 10%;
  column-gap: 20px;
  row-gap: 12px;

  &__header-title {
    font-size: 13px;
    color: $color-grey-dark;
    font-weight: 600;
  }

  &__row-data {
    font-size: 14px;

    &--title {
      font-size: 14px;
      font-weight: bold;
      color: $color-dark;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        text-decoration-color: $color-grey-dark;
        text-decoration-thickness: 2px;
      }
    }
  }
}

.no-tasks-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @include font-primary-semiBold;
  text-transform: uppercase;
  font-size: 16px;
}
