@import "../../../../style/typography.scss";

.relatorio__cardContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 35px;
}

.relatorio__grapfsContainer {
  margin-top: 50px;
  display: flex;
  gap: 35px;

  &__item {
    border: 1px solid $color-grey-light;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .chart-wrapper {
      flex-grow: 1;
      min-height: 300px;
    }
    &--bar {
      flex: 3; // Takes up 60% of the space
    }

    &--pie {
      flex: 2; // Takes up 40% of the space
    }

    &__title {
      @include font-primary-bold;
      font-size: 14px;
    }
  }
}

.recharts-responsive-container {
  min-width: 0;
}
