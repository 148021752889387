@import "./../../../style/typography.scss";
@import "./../../../style/util.scss";

.new-cliente {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 8;
  }

  .bottom {
    padding: 50px;
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 4fr;

    &-left {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 620px;
      }
    }

    &-right {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 30px;
      row-gap: 30px;
      ///////////

      .imagePicker {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-row: 1 / 3;

        .imagePickerContainer {
          width: 80%;
          height: 140px;
          border-radius: 4px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
            position: absolute;
          }

          .icon-file {
            width: 110%;
            height: 110%;
            position: absolute;
            cursor: pointer;

            svg {
              width: 100%;
              height: 100%;
              visibility: hidden;
            }
          }
        }
      }

      .comunicacao {
        display: flex;
        align-items: center;
        justify-content: space-around;

        label {
          display: flex;
          gap: 20px;
        }
      }

      // .formGroup {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 5px;

      //   .label {
      //     @include font-primary-regular;
      //     font-weight: 600;
      //     color: $color-grey-dark;
      //     align-self: flex-start;
      //     margin-left: 12px;
      //   }

      //   .input {
      //     padding: 13px 10px;
      //     border-radius: 4px;
      //     border: 1px solid $color-grey-dark;
      //     // border-bottom: 1.5px solid $color-grey-dark;
      //     background: transparent;
      //     @include font-primary-regular;
      //     font-size: 15px;
      //     color: $color-blue;

      //     &:focus {
      //       outline: none;
      //       border: 1px solid $color-grey-light;
      //       border-bottom: 2.5px solid $color-blue-other;
      //     }
      //   }

      //   input[type="checkbox"],
      //   select {
      //     cursor: pointer;
      //   }
      // }
    }
  }
}

// .imagePicker {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 0 40px 0;
// }

// .imagePickerContainer {
//   width: 150px;
//   height: 150px;
//   border-radius: 50%;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   img {
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     object-fit: cover;
//     position: absolute;
//   }

//   .icon-file {
//     width: 110%;
//     height: 110%;
//     position: absolute;
//     cursor: pointer;

//     svg {
//       width: 100%;
//       height: 100%;
//       visibility: hidden;
//     }
//   }

//   &::before {
//     content: "";
//     width: 110%;
//     height: 110%;
//     background-color: $color-blue-other;
//     border-radius: 50%;
//     position: absolute;
//     // box-shadow: 0px 0px 14px;
//   }
// }

.dataNascimento__cliente {
  width: 100%;
  height: 95%;
}

.dataNascimento__cliente > div > input {
  color: blue;
}
