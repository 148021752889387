@import "../../../../../style/typography.scss";
@import "../../../../../style/util.scss";
@import "../../../../../style/colors.scss";

.headingLine {
  th {
    text-transform: uppercase;
    font-size: 10px;
  }
}

.pdf-orcamento-wrapper {
  margin: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 1000px;
}

.pdf-orcamento-cabecalho {
  display: flex;
  margin-top: 25px;

  &-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      height: 120px;
    }
  }

  &-right {
    padding-top: 10px;
    flex: 2;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 13px;
    color: #202020;
    .data {
      font-size: 15px;
    }

    .blackText {
      font-weight: 700;
      margin-bottom: 3px;
    }
  }
}
// ---------------------------------------
.pdf-orcamento-outrosDados {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1.1px;

  &__titulo {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 3px;
    font-size: 15px;
    margin-top: 45px;
  }

  &__cabecalhoBold {
    font-weight: 700;
  }

  &__line {
    width: 450px;
    border-top: 0.5px solid black;

    &:first-child {
      margin-bottom: 11px;
    }
    &:last-child {
      margin-top: 11px;
    }
  }

  &__dadosDuplos {
    display: flex;
    gap: 25px;
  }
}

.divTabela {
  display: flex;
  margin-top: 25px;

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-top: 1px solid black;
    font-size: 12px;
    font-family: "Roboto", sans-serif;

    tr,
    td,
    th {
      padding: 5px;
      text-align: right;
    }

    th {
      font-size: 8pt;
      letter-spacing: 1.1px;
      color: #202020;
    }
    td {
      @include font-primary-regular;
    }

    .normalLine {
      border: none;
    }

    .primeiraLinha {
      border-top: 1px solid black;
      td {
        padding-top: 10px;
      }
    }
  }
}

.priceData {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 30%;
  align-self: end;

  &--1,
  &--4 {
    span {
      font-weight: 900;
    }
  }

  &Child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-left: 30px;
  }

  hr {
    border-top: 3px solid black;
    margin: 10px 0;
  }
}

.orcamento-rodape {
  @include font-primary-regular;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: auto;

  &--left {
    text-align: center;
    padding-top: 20px;
    &__text {
      font-size: 14px;
    }

    &__hr {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 55px;
    }
  }

  &--right {
    display: flex;
    flex-direction: column;

    &__lineTitle {
      color: #5c5c5c;
    }
  }

  &__div {
    padding: 8px 0;
    display: flex;
    gap: 20px;
    text-align: right;
    justify-content: flex-end;

    span {
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 1.1px;
    }
  }

  &__linha {
    grid-column: 1/-1;
    border-top: 1px solid black;
    &--pequena {
      width: 350px;
      margin-left: auto;
    }
  }
}

.valor_65px {
  width: 50px;
  color: #202020;
}

.pdf-orcamento-dadosEOdontograma {
  display: flex;
  justify-content: space-between;

  .odontograma-container img {
    width: 140px;
    height: auto;
  }
}

.pdf-orc-left {
  text-align: left !important;
}

.pdf-orc-center {
  text-align: center !important;
}
