@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.stock1stStep__container {
  display: flex;
  align-items: center;
  gap: 20px;

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $color-grey-light;
    padding: 15px 10px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    &:hover {
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }
  }

  &__icon {
    font-size: 50px !important;
    color: $color-grey-dark;
  }

  &__title {
    color: $color-blue;
    text-transform: uppercase;
    font-size: 16px;
    @include font-primary-semiBold;
  }

  &__subtitle {
    color: $color-grey-dark;
    font-size: 14px;
    @include font-primary-regular;
  }
}
