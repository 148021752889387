@import "../../../../../../../style/typography.scss";
@import "../../../../../../../style/colors.scss";

.cheque__tab__container {
  display: flex;
  flex-direction: column;
  &__dados-cheque {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  &__titulo {
    @include font-primary-regular;
    font-size: 15px;
  }

  &__campos-cheque {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--1,
    &--2 {
      display: flex;
      gap: 10px;

      & > div {
        flex: 1;
      }
    }
  }
}
