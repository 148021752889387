@import "../../../../style/typography.scss";
@import "../../../../style/util.scss";

$border-prescricao: 1.5px solid black;

// @media print {
//   @page {
//     size: 148mm 210mm;
//   }
// }

.pdf-wrapper {
  margin: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 700px;
  padding-top: 20px;
}

.republicaDeCv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &__text {
    @include font-primary-bold;
    font-size: 12px;
  }

  &__img {
    width: 60px;
    height: auto;
  }
}

.pdf-body {
  height: 640px;
  width: 100%;
  border: $border-prescricao;
}

.pdf-prescricao__rodape {
  display: flex;
  align-items: center;
  padding: 3px 0;
  font-size: 11px;

  &--bold {
    @include font-primary-bold;
    margin-left: 10px;
    margin-right: 3px;
  }
}

.pdf-prescricao-rodape--2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding: 5px 0;

  &__item {
    font-size: 8px;
  }
}

.pdf-prescricao__cabecalho {
  display: flex;
  border-bottom: $border-prescricao;
  &--left {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: $border-prescricao;
    text-align: center;

    span {
      font-size: 7px;

      &:last-child {
        margin-bottom: 5px;
      }
    }
    img {
      max-height: 60px;
      margin-top: 2px;
    }
  }

  &--right {
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 5px;

    &__title {
      @include font-primary-bold;
      text-align: center;
      font-size: 13px;
    }

    &__campo {
      .boldSpan {
        @include font-primary-bold;
        font-size: 10px;
      }
      .normalSpan {
        margin-left: 5px;
        @include font-primary-regular;
        font-size: 10px;
      }
    }
  }
}

.pdf-prescricao__middle {
  height: 285px;
  max-height: 285px;
  border-bottom: $border-prescricao;
  display: flex;

  &--left {
    flex: 9;
    max-width: 1061px;
    border-right: $border-prescricao;

    .smallDiv--1st {
      display: flex;
      border-bottom: $border-prescricao;
      height: 34px;
      align-items: center;
      margin-bottom: 20px;

      .tinyDiv {
        // border-right: $border-prescricao;
        padding: 3px;
        img {
          width: 15px;
          height: auto;
        }
      }

      &-txt {
        font-size: 10px;
        padding: 2px;
      }
    }
  }

  &--right {
    flex: 3;
    display: flex;

    &-number {
      flex: 2;
      border-right: $border-prescricao;
    }

    &-extenso {
      flex: 7;
    }
  }
}

.middle-content {
  display: flex;
}

.pdf-prescricao__middle__data {
  height: 25%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-wrap: break-word;
  margin: 0 7px;
  font-size: 14px;

  &--2 {
    font-style: italic;
  }

  &--itemPrescricao {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &:first-of-type {
    margin-top: 5px;
  }

  // &:not(:last-child) {
  //   margin-bottom: 5px;
  // }

  &--center {
    justify-content: center;
  }
}

.pdf-prescricao__bottom {
  display: flex;

  &--left {
    border-right: $border-prescricao;
    flex: 9;
    display: flex;

    &--1 {
      flex: 1;
      border-right: $border-prescricao;
    }

    &--2 {
      flex: 1;
    }
  }

  &--right {
    flex: 3;
    border-bottom: $border-prescricao;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__span1 {
      width: 90%;
      margin-top: 10px;
      font-size: 11px;
      text-align: center;
    }

    &__span2 {
      margin-top: 10px;
      @include font-primary-bold;
      font-size: 11px;
    }
  }
}

.smallDiv {
  border-bottom: $border-prescricao;
  padding: 2px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;

  &--bold {
    @include font-primary-bold;
  }

  &--bottom {
    height: 12px;
    font-size: 10px;
  }

  &--top {
    margin-bottom: 10px;
  }
}

.div__carimbo {
  height: 55px;
  border-bottom: $border-prescricao;
  font-size: 11px;
  display: flex;

  .pdf-prescricao__bottom-top-span {
    margin-left: 4px;
    margin-top: 2px;
  }
}

.assinaturaEData {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 110px;
  border-bottom: $border-prescricao;
  align-items: center;
  font-size: 11px;

  hr {
    border-top: $border-prescricao;
    width: 80%;
    margin-top: 40px;
  }

  .data {
    margin-bottom: 5px;
  }
}

.pdf-prescricao--overline {
  border-top: 1px solid black; /* Set border thickness, style, and color */
  padding-top: 1px;
}
