@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.categoriasFinanceiro__container {
  display: flex;
  flex-direction: column;

  &__btn-adicionar {
    width: 220px;
    grid-column: 1 / -1;
    margin: 30px 0 0 30px;
  }

  &__categoria {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    background-color: $color-white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &--1 {
      @include font-primary-regular;
      color: $color-dark;
      font-size: 18px;
      font-weight: 500;
    }

    &--2 {
      display: flex;
      justify-content: flex-end;

      span {
        @include font-primary-regular;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }

        &:first-child {
          color: $color-red;
          margin-right: 25px;
        }
        &:last-child {
          color: $color-dark;
        }
      }
    }
  }
}

.categoriasFinanceiro__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin: 30px;
}

.categoriasFinanceiro__titulo {
  @include font-primary-semiBold;
  grid-column: 1/-1;
  font-size: 18px;
}
#modal__adicionar-categoria {
  width: 35vw;
}

.categoriasGeral__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin: 30px;

  &__btn-adicionar {
    width: 220px;
    grid-column: 1 / -1;
  }
}
