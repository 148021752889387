@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.customInputContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customInput::placeholder {
  opacity: 0;
}

.customInput,
.customInputSelect,
.customInputTextarea {
  width: 100%;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid $color-border-input;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  @include font-primary-regular;
  color: $color-blue-dark;
}

.customInputSelect {
  cursor: pointer;
}

.customInputLabel {
  position: absolute;
  left: 10px;
  top: 40%;
  transform: translateY(-20%);
  font-size: 14px;
  color: $color-grey-dark;
  letter-spacing: 1px;
  transition: 0.3s;
  @include font-primary-regular;
  cursor: text;
}

.customInputLabelSelect {
  position: absolute;
  left: 10px;
  letter-spacing: 1px;
  @include font-primary-regular;
  top: -8px;
  font-size: 13px;
  color: $color-grey-dark;
  background: #fff;
  padding: 0 7px;
}

.label--up {
  top: -7% !important;
  background-color: white !important;
}

.customInput:focus + label,
.customInput:not(:placeholder-shown) + label {
  top: -10px;
  font-size: 13px;
  color: $color-grey-dark;
  background: #fff;
  padding: 7px;
}

.customInput:focus {
  border: 1px solid $color-blue;
}

.customInputError,
.customInputError:focus {
  border-color: $color-red;
}
