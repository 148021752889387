@import "../../../../../style/colors.scss";
@import "../../../../../style/typography.scss";
@import "../../../../../style/util.scss";
@import "../../../../../style/fluxo-atendimento__modals.scss";

.avaliacao__modal {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
}

.orcamento__modal {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
}

.orcamento__modal--beneficiario {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr;
}

.orcamento__modal__valores {
  grid-column: 1/ -1;
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  @include font-primary-regular;

  .orcamento__modal__valores--container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__descricao {
    color: $color-grey-dark;
  }

  &__valor {
    &--total {
      font-weight: 600;
    }
  }
}

.orcamentoIsentoTitleMessage {
  color: crimson;
}

.verOrcamentoModal__valorCredencialContainer {
  margin-top: 20px;
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;

  &__col {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__row {
      display: flex;
      gap: 15px;

      .orcamento__modal__valores__valor {
        margin-left: auto;
      }
    }
  }
}
