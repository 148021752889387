.motivoCancelamento__modal {
  &__textarea {
    padding: 10px;
    border: 1px solid #ccc;
  }

  &__select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
  }

  &__erro {
    color: crimson;
    text-align: center;
    margin-bottom: 25px;
  }
}

#modal__motivoCancelamento {
  width: 40%;
  min-width: 400px;
}

.motivoCancelamento__modal__container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  margin-bottom: 25px;
  margin-top: 10px;
}
