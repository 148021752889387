@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.collapse {
  &__title {
    display: flex;
    align-items: center;
    gap: 5px;
    @include font-primary-semiBold;
    color: $color-blue-dark;

    &__icon {
      font-size: 20px !important;
      color: $color-dark;
    }
  }

  &__content__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 10px;
    padding: 5px 25px;

    .collapse__labelCheckbox {
      display: flex;
      align-items: center;
      gap: 7px;
      @include font-primary-regular;
      cursor: pointer;

      input[type="checkbox"] {
        width: 15px;
        height: 15px;
        accent-color: $color-blue;
        cursor: pointer;
      }
    }
  }
}

.permissoes__select__container {
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    @include font-primary-semiBold;
    color: $color-blue-dark;
  }
}

.permissoes__btn__container {
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  .submit-btn {
    padding: 5px 20px;
    cursor: pointer;
  }
}

.collapse__checkbox--all__container {
  grid-column: 1/-1;
  display: flex;
  justify-self: end;
  margin-right: 45px;

  .collapse__labelCheckbox--all {
    @include font-primary-semiBold;
    color: $color-blue;
  }
}

.permissoes__headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 25px;

  &__buttons {
    display: flex;
    gap: 15px;
  }
}

.red-button {
  padding: 7px 18px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 0.7px;
  border-radius: 3px;
  transition: all 0.2s;
  cursor: pointer;
  background-color: #e25050;
  color: white;
  border: none;
}
